<template>
  <div :style="getWidth()">
    <div class="input-title">{{ title }} <span v-if="isRequired" class="required-color">*</span></div>
    <div v-if="type == 'text'" class="input-containear">
      <input type="text" v-model="inputValue" v-on:keyup.enter="returnValue()" class="input-value-standard"
        :placeholder="placeholder">
      <span v-on:click="returnValue()" class="material-icons input-icon">search</span>
    </div>
    <div v-if="type == 'select'" class="input-containear">
      <select v-model="selectValue" class="input-select-value">
        <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
        <option v-for="(x, idx) in list" :value="x.value" :key="idx">{{ x.text }}</option>
      </select>
    </div>
    <div v-if="type == 'disabled'" class="input-containear">
      <select disabled v-model="selectValue" class="input-select-value" style="cursor: not-allowed;">
        <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
      </select>
    </div>
    <div v-if="type == 'money'" class="input-containear">
      <money class="input-value-standard" min="0" v-bind="money" v-model="inputValue" @change.native="returnValue()"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandardInput',
  props: ['title', 'width', 'type', 'list', 'value', 'action', 'placeholder', 'disabled', 'isRequired'],
  data() {
    return {
      inputValue: '',
      selectValue: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
    }
  },
  created() {
    this.inputValue = this.value
  },
  methods: {
    getWidth() { return 'width: ' + this.width },
    returnValue() { this.action(this.inputValue) },
    resetValue() { this.selectValue = undefined }
  },
  watch: {
    selectValue(newValue) { this.action(newValue) },
    value(newValue) { if (newValue == '') this.resetValue() },
  }
}
</script>

<style scoped>
.input-title {
  font-weight: 400;
  font-size: 2em;
  letter-spacing: 0.15px;
  color: #6e6e6e;
}

.input-containear {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.6vw;
  margin-top: 5px;
  display: flex;
  align-items: center;
  height: 6vh;
}


.input-value-standard {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 1.3em;
  letter-spacing: 0.15px;
  color: #6e6e6e;
}

.input-value-standard:focus {
  border: none;
  outline: none;
}

.input-icon {
  color: #6e6e6e;
  cursor: pointer;
  font-size: 1.7em;
}

.input-select-value {
  border: none;
  font-weight: 400;
  font-size: 1.3em;
  letter-spacing: 0.15px;
  color: #6e6e6e;
  flex: 1;
  height: 24px;
  width: 100%;
}

.input-select-value:focus {
  border: none;
  outline: none;
}

.required-color {
  color: red;
}
@media only screen and (max-width: 800px){
  .input-containear {
    height: 7.5vh;
  }
}

</style>
