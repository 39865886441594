<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="invitation-modal__main-container">
        <div class="invitation-modal__header-container">
          <span class="invitation-modal__header-title">
            Convite de Fornecedores
          </span>
          <span @click="closeDialog" class="material-icons invitation-modal__closebtn-icon">
            cancel
          </span>
        </div>
        <div class="invitation-modal-body">
          <div class="overflow-container">
            <div class="invitation-modal__actions-container">
              <!-- <StandardButton class="button-containear"  :action="() => { invitation = null; modal.nobackbutton = false }" :iconName="'arrow_back'" :text="'Voltar'"/>
              <StandardButton class="button-containear" v-if="!invitation" :action="closeDialog" :iconName="'arrow_back'" :text="'Voltar'"/> -->
              
              <!-- <div v-if="invitation" class="invitation-modal__send-icon-wrapper">
                <span class="material-icons-outlined invitation-modal__send-icon">
                  arrow_back
                </span>
                <cc-button-send class="invitation-modal__send-btn" :content="'Voltar'" :classes="'fill success'" @click="closeDialog()" :nolink="true" :icon="'far fa-paper-plane'" />
              </div> -->

              <input id="fileUpload" type="file" @change="readFile" hidden/>
              <div class="invitation-modal__actions__wrapper button-containear"
                  @click="chooseFiles()"
                  :content="'Importar'">
                <img src="../../../icons/excel-icon.svg" class="invitation-modal__actions-icon excel-icon">
                <span class="invitation-modal__actions-title">
                  Importar Excel
                </span>
              </div>
              <StandardButton class="button-containear" style="margin-right: 10px;" :action="renotify" :iconName="'notifications_active'" :text="'Reenviar Notificação'"/>
              <StandardButton class="button-containear" v-if="!invitation" :action="add" :iconName="'mail'" :text="'Novo Convite'"/>

              <InvitationButton class="invitation-rigth" v-if="invitation" text="Voltar" icon="arrow_back" :action="() => { invitation = null; modal.nobackbutton = false }" />
              <InvitationButton class="invitation-rigth" v-else text="Voltar" icon="arrow_back" :action="closeDialog"/>
              <!-- <div v-if="invitation" @click="invitation = null; modal.nobackbutton = false" class="invitation-modal__actions__wrapper">
                <span class="material-icons invitation-modal__actions-icon">
                  arrow_back
                </span>
                <span class="invitation-modal__actions-title">
                  Voltar
                </span>
              </div>
              <div v-if="!invitation" @click="closeDialog" class="invitation-modal__actions__wrapper">
                <span class="material-icons invitation-modal__actions-icon">
                  arrow_back
                </span>
                <span class="invitation-modal__actions-title">
                  Voltar
                </span>
              </div>
              <div @click="renotify" v-if="!invitation" class="invitation-modal__actions__wrapper">
                <span class="material-icons-outlined invitation-modal__actions-icon">
                  notifications_active
                </span>
                <span class="invitation-modal__actions-title">
                  Reenviar Notificação
                </span>
              </div>
              <div @click="add" v-if="!invitation" class="invitation-modal__actions__wrapper">
                <span class="material-icons-outlined invitation-modal__actions-icon">
                  mail
                </span>
                <span class="invitation-modal__actions-title">
                  Novo Convite
                </span>
              </div> -->
            </div>
              <form action="" @submit.prevent="handleSubmit(save)">
              <div class="invitation-modal__form-container" v-if="invitation">
                <div class="invitation-modal__input-wrapper">
                    <span class="invitation-modal__input-title">Telefone: </span>
                    <input class="invitation-modal__input"
                          v-mask="'(##) ####-#####'"
                          type="text"
                          v-model="invitation.receiver_phone">
                </div>

                <!-- <StandardButton :iconName="'search'" :action="search_by_phone" class="invitation-search-icon"/> -->
                
                
                <!-- <div v-if="searchLoading" class="search-icon-container" @click="search_by_phone()">
                  <span class="material-icons-outlined " >refresh</span>
                </div>
                <div v-else class="search-icon-container" @click="search_by_phone()">
                  <span class="material-icons-outlined " >search</span>
                </div> -->
                
                <InvitationLoading v-if="searchLoading"/>
                <InvitationButton v-else icon="search" :action="() => search_by_phone()"/>

                <div class="invitation-modal__input-wrapper">
                    <span class="invitation-modal__input-title">Nome Vendedor: </span>
                    <input class="invitation-modal__input"
                          id="name-input-invitation"
                          type="text"
                          v-model="invitation.receiver_name"
                          :disabled="invitation.lock">
                </div>
                
                <div class="invitation-modal__input-wrapper">
                  <span class="invitation-modal__input-title">Empresa:</span>
                  <input class="invitation-modal__input" type="text" v-model="invitation.receiver_company_name" :disabled="invitation.lock">
                </div>

                <!-- <div class="invitation-modal__send-icon-wrapper" v-if="!invitation.lock">
                  <span class="material-icons-outlined invitation-modal__send-icon">
                    mail
                  </span>
                  <cc-button-send class="invitation-modal__send-btn" :content="'Enviar'" :classes="'fill success'" type="submit" :nolink="true" :icon="'far fa-paper-plane'" />
                </div> -->
                <InvitationButton class="invitation-rigth" :disabled="invitation.lock" text="Enviar" icon="mail" :action="save"/>

              </div>
              </form>
            <!-- tabela -->
            <cc-loader v-if="loading" />
            <div v-else>
              <div class="modal-table-container">
                <div class="modal-table-header">
                  <div class="modal-table-colum blank-space-width"></div>
                  <div class="modal-table-colum name-width">Nome</div>
                  <div class="modal-table-colum phone-width">Telefone</div>
                  <div class="modal-table-colum company-width">Empresa</div>
                  <div class="modal-table-colum status-width">Status</div>
                  <div class="modal-table-colum send-width">Enviado em</div>
                  <div class="modal-table-colum actions-width">Ações</div>
                </div>
                <div v-for="(invitation, idx) in invitations"
                      :key="invitation.id"
                      :class="{ invitation: true,
                                canceled: invitation.status == 'CANCELED',
                                waiting: invitation.status == 'WAITING_CONFIRMATION',
                                confirmed: invitation.status == 'CONFIRMED',
                                'page-table-line-special': idx % 2 != 0}">
                  <div class="modal-table-row">
                    <div class="modal-table-colum blank-space-width">
                      <i class="far fa-envelope"></i>
                      <i class="far fa-check-circle"
                        title="Whatsapp válido"
                        v-show="invitation.is_valid_whatsapp == 1">
                      </i>
                    </div>

                    <!-- desktop -->
                    <div class="desktop modal-table-colum name-width">
                      <div class="invitation-modal-mobile-header">Nome</div>
                      {{ invitation.receiver_name }}
                    </div>
                    <!-- desktop -->
                    <div class="desktop modal-table-colum phone-width">
                      <div class="invitation-modal-mobile-header">Telefone</div>
                      {{ invitation.receiver_phone }}
                    </div>
                    <!-- desktop -->
                    <div class="desktop modal-table-colum company-width" :title="invitation.receiver_company_name">
                      {{ invitation.receiver_company_name }}
                    </div>
                    <!-- desktop -->
                    <div class="desktop modal-table-colum status-width">
                      <div class="invitation-modal-mobile-header">Status</div>
                      {{ translate_status(invitation.status) }}
                    </div>
                    <!-- desktop -->
                    <div class="desktop modal-table-colum send-width">
                      <div class="invitation-modal-mobile-header">Enviado em</div>
                      {{ invitation.created_at | formatDate }}
                    </div>
                    <!-- desktop -->
                    <div class="desktop modal-table-colum actions-width">
                      <div class="invitation-modal-mobile-header">Ações</div>
                      <span class="material-icons-outlined trash-icon" title="Prorrogar data de expiração" v-if="invitation.status == 'WAITING_CONFIRMATION'" @click="cancel(invitation)">delete</span>
                    </div>

                    <!-- mobile -->
                    <div class="mobile mobile-table-row">
                      <div class="mobile-table-colum">
                        <div class="invitation-modal-mobile-header">Nome</div>
                        {{ invitation.receiver_name }}
                      </div>
                      <div class="mobile-table-colum" style="display: flex; flex-direction: column; align-items: center;">
                        <div class="invitation-modal-mobile-header">Telefone</div>
                        {{ invitation.receiver_phone }}
                      </div>
                      <div class="mobile-table-colum" style="display: flex; flex-direction: column; align-items: flex-end;">
                        <div class="invitation-modal-mobile-header">Empresa</div>
                        {{ invitation.receiver_company_name }}
                      </div>
                    </div>

                    <!-- mobile -->
                    <div class="mobile mobile-table-row">
                      <div class="mobile-table-colum">
                        <div class="invitation-modal-mobile-header">Status</div>
                        {{ translate_status(invitation.status) }}
                      </div>
                      <div class="mobile-table-colum" style="display: flex; flex-direction: column; align-items: center;">
                        <div class="invitation-modal-mobile-header">Enviado em</div>
                        {{ invitation.created_at | formatDate }}
                      </div>
                      <div class="mobile-table-colum" style="display: flex; flex-direction: column; align-items: flex-end;">
                        <div class="invitation-modal-mobile-header">Ações</div>
                        <span class="material-icons-outlined trash-icon" title="Prorrogar data de expiração" v-if="invitation.status == 'WAITING_CONFIRMATION'" @click="cancel(invitation)">delete</span>
                      </div>
                    </div>

                  </div>
                </div>
                <tr style="display: flex; justify-content: center;" v-show="total_items > 1">

              </tr>

              </div>

            </div>
            <Paginacao classes="orange justify-end"
                            :total="total_items"
                            :items_by_page_value="15"
                            :page="page"
                            :page_limit="9"
                            v-on:change="($event) => {
                            load($event)
                            return $event }">
                  </Paginacao>
          </div>
        </div>
        <!-- <div v-if="!invitation" class="invitation-modal__table-container">
          <table class="invitation-modal__table-wrapper">
            <thead class="invitation-modal__table-thead">
              <tr class="invitation-modal__table-thead-tr">
                <th class="invitation-modal__table-th"></th>
                <th class="invitation-modal__table-th">Nome</th>
                <th class="invitation-modal__table-th">Telefone</th>
                <th class="invitation-modal__table-th">Empresa</th>
                <th class="invitation-modal__table-th">Status</th>
                <th class="invitation-modal__table-th">Enviado em</th>
                <th class="invitation-modal__table-th">Ações</th>
              </tr>
            </thead>
            <tbody class="invitation-modal__table-tbody">
              <tr v-for="invitation in invitations"
                  :key="invitation.id"
                  :class="{ invitation: true,
                            canceled: invitation.status == 'CANCELED',
                            waiting: invitation.status == 'WAITING_CONFIRMATION',
                            confirmed: invitation.status == 'CONFIRMED', }">
                <td class="invitation-modal__table-td">
                    <i class="far fa-envelope"></i>
                    <i class="far fa-check-circle"
                       title="Whatsapp válido"
                       v-show="invitation.is_valid_whatsapp == 1"></i>
                </td>
                <td class="invitation-modal__table-td">{{ invitation.receiver_name }}</td>
                <td class="invitation-modal__table-td">{{ invitation.receiver_phone }}</td>
                <td class="invitation-modal__table-td">{{ invitation.receiver_company_name }}</td>
                <td class="invitation-modal__table-td">{{ translate_status(invitation.status) }}</td>
                <td class="invitation-modal__table-td">{{ invitation.created_at | formatDate }}</td>
                <td class="invitation-modal__table-td"><span class="material-icons-outlined trash-icon" title="Prorrogar data de expiração" v-if="invitation.status == 'WAITING_CONFIRMATION'" @click="cancel(invitation)">delete</span></td>
              </tr>
              <tr v-show="total_items > 1">
                <Paginacao classes="orange justify-end"
                           :total="total_items"
                           :items_by_page_value="15"
                           :page="page"
                           :page_limit="9"
                           v-on:change="($event) => {
                           load($event)
                           return $event }">
                </Paginacao>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
  </div>
    <!-- <cc-modal :modal="modal" @close="close">
        <div slot="header-actions">
            <div class="row">
                <div class="col actions">
                    <input id="fileUpload" type="file" @change="readFile" hidden/>

                    <cc-button-excel @click="chooseFiles()" :content="'Importar'"   v-if="!invitation" />
                    <cc-button-resend :content="'Reenviar Notificações'"  @click="renotify" v-if="!invitation" />
                    <cc-button-new :content="'Novo Convite'"  @click="add" v-if="!invitation" />
                    <cc-button-back :content="'Voltar'"  @click="invitation = null; modal.nobackbutton = false" v-else/>
                </div>
            </div>
        </div>
        <div slot="body" class="container ml-3 mb-2">
            <div class="row">
                <div class="col" v-if="invitation">
                    <div class="row">
                        <div class="col">
                            <ValidationObserver v-slot="{ handleSubmit }">
							    <form action="" @submit.prevent="handleSubmit(save)">
                                    <div class="row">
                                        <div class="col-md-3 col-lg-4">
                                            <label>Nome:<span style="color:red !important">*</span></label>
                                            <ValidationProvider rules="required" v-slot="v">
                                                <cc-input type="text" v-model="invitation.receiver_name" :readonly="invitation.lock"></cc-input>
                                                <span class="error">{{ v.errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-3 col-lg-3">
                                            <label>Telefone:<span style="color:red !important">*</span></label>
                                            <ValidationProvider rules="required|phone" v-slot="v">
                                                <input v-mask="'(##) ####-#####'" class="form-control" type="text"
                                                    v-model="invitation.receiver_phone"
                                                    @focus="unlock_invitation"
                                                    :readonly="invitation.lock"
                                                    @blur="() => search_by_phone()" />
                                                <span class="error">{{ v.errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-4 col-lg-3">
                                            <label>Empresa:</label>
                                            <cc-input type="text" v-model="invitation.receiver_company_name" :readonly="invitation.lock"></cc-input>
                                        </div>
                                        <div class="col col-2 d-flex justify-content-center align-items-end">
                                            <cc-button-send :content="'Enviar'" :classes="'fill success'" type="submit" :nolink="true" :icon="'far fa-paper-plane'" />
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
                <div class="col" v-show="!invitation">
                    <cc-loader v-show="loading"/>
                    <no-data-available v-if="!loading && invitations && invitations.length == 0" :text="'Sem convites enviados'" />
                    <table class="table" v-show="!loading && invitations && invitations.length > 0">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="pl-2">Nome</th>
                                <th>Telefone</th>
                                <th>Empresa</th>
                                <th>Status</th>
                                <th>Enviado em</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="invitation in invitations" v-bind:key="invitation.id"
                                :class="{
                                    invitation: true,
                                    canceled: invitation.status == 'CANCELED',
                                    waiting: invitation.status == 'WAITING_CONFIRMATION',
                                    confirmed: invitation.status == 'CONFIRMED',
                                }">
                                <td class="text-center">
                                    <i class="far fa-envelope"></i>
                                    <i class="far fa-check-circle"
                                        title="Whatsapp válido"
                                        v-show="invitation.is_valid_whatsapp == 1" />
                                </td>
                                <td>{{ invitation.receiver_name }}</td>
                                <td>{{ invitation.receiver_phone }}</td>
                                <td>{{ invitation.receiver_company_name }}</td>
                                <td>{{ translate_status(invitation.status) }}</td>
                                <td>{{ invitation.created_at | formatDate }}</td>
                                <td>
                                    <i class="fa fa-trash remove-btn" title="Prorrogar data de expiração" v-if="invitation.status == 'WAITING_CONFIRMATION'" @click="cancel(invitation)" aria-hidden="true"></i>
                                </td>
                            </tr>
                            <tr v-show="total_items > 1">
                                <td colspan="7" style="height:6rem">
                                    <Paginacao
                                        classes="orange justify-end"
                                        :total="total_items"
                                        :items_by_page_value="15"
                                        :page="page"
                                        :page_limit="9"
                                        v-on:change="($event) => {
                                            load($event)
                                            return $event
                                        }"
                                    ></Paginacao>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </cc-modal> -->
</template>

<script >
import InvitationService from '@/services/v3/invitation.service'
import ProviderService from '@/services/v3/provider.service'
import QuotationService from "@/services/QuotationService";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import Paginacao from "@/components/cliente/base-components/Pagination";
import readXlsxFile from 'read-excel-file'
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import InvitationButton from './InvitationButton.vue'
import InvitationLoading from './InvitationLoading.vue';

export default {
    mixins: [ loaderMixin ],
    props: {
      closeDialog: {
        type: Function,
      }
    },
    components: {
        ValidationProvider,
        Paginacao,
		    ValidationObserver,
        readXlsxFile,
        StandardButton,
        InvitationButton,
        InvitationLoading
    },
    data() {
        return  {
            svc: new InvitationService(),
            pro_svc: new ProviderService(),
            req_svc: new QuotationService(),
            invitations: [],
            invitation: {lock: true},
            loading: true,
            searchLoading:false,
            total_items: 0,
            items_by_page_value: 10,
            page: 1,
            modal: {
                title: "Convites",
                subtitle: 'Convide vendedores para sua rede',
                icon_title: 'far fa-envelope',
                nobackbutton: false,
                style: {
                    width: "70%"
                }
            }
        }
    },
    methods: {
        chooseFiles: function() {
            document.getElementById("fileUpload").click()
        },
        translate_status(status) {
            switch(status) {
                case "CANCELED":
                    return "Cancelado"
                case "WAITING_CONFIRMATION":
                    return "Aguardando Confirmação"
                case "CONFIRMED":
                    return "Confirmado"
                default:
                    return "Não Reconhecido"
            }
        },
        save() {
            this.present_loader("Enviando convite...")

            this.search_by_phone(() => {
                this.svc.send(this.invitation).then(() => {
                    if(this.invitation.invited_user_id) {
                        this.req_svc.enableSeller(this.invitation.invited_user_id)
                        this.$emit('load_sellers')
                    }
                    this.load()
                    .then(() => this.dismiss_loader())
                    .then(() => this.invitation = {lock: true})
                }).catch((err) => {
                    if(err.response && err.response.data.errors) {
                        this.$store.dispatch('notification/add', {
                            type: 'error',
                            message: err.response.data.errors && err.response.data.errors[0]
                        })
                    }
                    this.dismiss_loader()
                })
            })
        },
        cancel(invitation) {
            this.confirm_action({
				message: "Confirma cancelamento do convite?",
				callback: () => {
                    this.present_loader("Cancelando Convite...")
                    this.svc.cancel(invitation).then(() => {
                        this.load().then(() => this.dismiss_loader())
                    })
				}
			})
        },
        async readFile(event) {
            this.present_loader(`Enviando convites... `);
            let xlsxfile = event.target.files ? event.target.files[0] : null;
            readXlsxFile(xlsxfile).then( async (data) => {
               for (let index = 1; index < data.length; index++) {
                    let invitation = {
                        receiver_name:data[index][0],
                        invited_user_id:null,
                        lock:true,
                        receiver_company_name:data[index][1],
                        receiver_phone:data[index][2]

                    }

                    if(invitation.receiver_phone.toString().length == 10 || invitation.receiver_phone.toString().length == 11){
                        var res = await this.testePhone(invitation.receiver_phone);
                        if(res != 'erro'){
                                invitation.receiver_name = res.usu_nome
                                invitation.receiver_company_name = res.provider.for_nome
                                invitation.invited_user_id = res.usu_id
                                this.$set(invitation, 'lock', true);
                                this.req_svc.enableSeller(invitation.invited_user_id)
                            }


                            let resInvite = await this.sendInvite(invitation);
                            if(resInvite.errors){
                                console.log(resInvite.errors)
                            }

                    }
                }
            this.$emit('load_sellers')
            this.present_info("Convites enviados!");
            this.load();
            })
        },
        async sendInvite(invitation){
           return this.svc.send(invitation)
                    .then(response => response.data).then((data) => {
                        return data;
                    }).catch((e) => {
                        return e.response.data;
                    })
        },
        async testePhone( phone){
          return this.pro_svc.find_by_phone(phone)
            .then(response => response.data).then((data) => {
                return data;
            }).catch(() => {
                return 'erro';
            })
        },
        renotify() {
            this.confirm_action({
				message: "Confirma reenvio de notificações dos convites pendentes?",
				callback: () => {
                    this.present_loader("Reenviando notificações...")
                    this.svc.renotify_all().then(() => {
                        this.present_info("Notificações Enviadas!")
                    })
				}
			})
        },
        unlock_invitation() {
            this.$set(this.invitation, 'lock', false)
            this.invitation.invited_user_id = null
        },
        search_by_phone(callback = null) {
          this.searchLoading = true
            return this.pro_svc.find_by_phone(this.invitation.receiver_phone)
            .then(response => response.data).then((data) => {
                this.searchLoading = false

                this.$set(this.invitation, 'receiver_name', data.usu_nome)
                this.$set(this.invitation, 'receiver_company_name', data.provider.for_nome)
                this.$set(this.invitation, 'invited_user_id', data.usu_id)
                this.$set(this.invitation, 'lock', true)

                this.svc.send(this.invitation).then(() => {
                    if(this.invitation.invited_user_id) {
                        this.req_svc.enableSeller(this.invitation.invited_user_id)
                        this.$emit('load_sellers')
                    }
                    this.load()
                    .then(() => this.dismiss_loader())
                    .then(() => this.invitation = {lock: true})
                })
            }).catch(() => {
              this.searchLoading = false
              this.$set(this.invitation, 'lock', false)
              setTimeout(() => document.getElementById('name-input-invitation').focus(), 100);
              
              if(callback)callback(null)
            })
        },
        add() {
            this.invitation = {lock: true}
            this.modal.nobackbutton = true;
        },
        close() {
            this.$emit('close')
        },
        load(page = this.page) {
            this.loading = true

              this.page = page
            return this.svc.list({ page }).then(response => response.data).then((data)=>{
                this.invitations = data.data
                this.loading = false

                setTimeout(() => {
                  this.total_items = data.total
                }, 100);
            })
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container {
  background-color: white;
  min-width: 80vw;
  border-radius: 12px;
}
.invitation-modal__main-container {
  width: 80vw;
  height: 110vh;
  overflow-y: hidden;
}
.invitation-modal-mobile-header{
  display: none;
}
.invitation-modal__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.invitation-modal__header-title {
  color: #FFFFFF;
  letter-spacing: -2%;
  font-weight: 500;
  font-size: 2.5em;
}
.invitation-modal__closebtn-icon {
  color: #FFFFFF;
  font-size: 3em;
  cursor: pointer;
}
.invitation-modal__actions-container {
  display: flex;
  align-items: center;
  margin: 2em;
}
.invitation-modal__actions__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-right: 1vw;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #fff;
  cursor: pointer;
}
.invitation-modal__actions__wrapper:hover {
  background-color: var(--primary-color);
}
.invitation-modal__actions__wrapper:hover > .excel-icon {
  background-color: #fff;
  /* color: #fff; */
}
.invitation-modal__actions__wrapper:hover > .invitation-modal__actions-title {
  color: #fff;
}
.invitation-modal__actions-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}
.invitation-modal__send-icon {
  color: var(--primary-color);
  position: relative;
  left: 40px;
  top: 10px;
}
.excel-icon {
  width: 20px;
  color: red;
}
.invitation-modal__actions-title {
  font-weight: 600;
  font-size: 1.2em;
  color: #202020;
}
.trash-icon {
  color: #CD2A2A;
  cursor: pointer;
}
.invitation-modal__table-container {
  margin: 20px;
  overflow-y: auto;
  max-height: 60vh;
}
.invitation-modal__table-wrapper {}
.invitation-modal__table-thead {}
.invitation-modal__table-thead-tr {
  background-color: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  color: #605F5F;
  font-size: 1.2em;
  font-weight: 400;
}
.invitation-modal__table-th {
  font-family: Rubik;
  font-size: 1.2em;
  font-weight: 600;
}
.invitation-modal__table-td {
  font-family: Rubik;
  color: #605F5F;
  font-size: 1.2em;
  font-weight: 300;
}
.invitation-modal__send-invitation-btn {
}
.invitation-modal__form-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 2em;
  gap: 2%;
}
.invitation-modal__input-wrapper {}
.invitation-modal__input-title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.invitation-modal__input {
  padding: 10px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  width: 17vw;
}
.invitation-modal__input:focus{
  outline: none;
}
.button-containear {
  height: 7.5vh;
}
.invitation-modal__btn-wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0px 10px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  cursor: pointer;
}
.invitation-modal__send-btn-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}
.invitation-modal__send-btn {
  margin-top: 20px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  padding: 25px;
  color: #505050;
}
.invitation-modal__send-icon-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.search-icon-container{
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  color: #505050;
  height: 2.5rem;
  border-radius: 10px;
  width: 6%;
  text-align: center;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  cursor: pointer;
}
.invitation-rigth{
  margin-left: auto
}
.invitation-modal-body {
  height: 95vh;
}
.overflow-container{
  overflow-x: auto;
  height: 95%;
}
.modal-table-container{
  min-height: 40vh;
  margin: 0em 2em;
}
.modal-table-header{
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 1vh 0;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 17px;
  color: #605F5F;
  margin-bottom: 1vh;
  font-weight: 600;
}
.modal-table-colum{
  padding-left: 0.5vw;
  padding: 0.2em;
}
.invitation-search-icon{
  min-width: 0;
  width: 3vw;
  height: 3rem;
}
.modal-table-row{
  display: flex;
  font-weight: 300;
  font-size: 1.1em;
  color: #605F5F;
  margin-bottom: 1vh;
  align-items: center;
}
.blank-space-width {
  width: 8%;
}
.name-width {
  width: 18%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone-width {
  width: 18%;
}
.company-width {
  width: 18%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-width {
  width: 14%;
}
.send-width {
  width: 18%;
}
.actions-width {
  width: 8%;
}
.page-table-line-special{
  background: #F7F7F7;
}
.mobile{display: none;}


@media only screen and (max-width: 800px) {

  .mobile{display: flex;}
  .modal-mask{font-size: 2vw;}
  .modal-content-container{width: 110vw;}
  .invitation-modal__main-container{width: 110vw;}
  .invitation-modal__actions-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .invitation-modal__actions__wrapper {
    margin: 10px;
  }
  .invitation-modal__table-container {
    height: 26vh;
  }
  .invitation-modal__form-container {
    flex-direction: column
  }
  .invitation-modal__input-wrapper {
    margin: 15px 0px;
  }
  .invitation-modal__input{
    width: 100%;
  }
  .invitation-modal__btn-wrapper {
    margin: 10px 0px;
  }
  .invitation-modal__send-icon-wrapper {
    position: relative;
    left: -20px;
  }
  .invitation-modal__actions__wrapper {
    margin: 0em;
  }
  .modal-table-container {
    margin: 0em;
  }
  .modal-table-colum {
    margin-right: 3em;
  }
  .button-containear {
    width: 45vw;

    margin-bottom: 1vh;
  }
  .modal-table-header{display: none }
  .modal-table-row{
    flex-direction: column;
    gap: 1vh;
    font-size: 1.2em;
    margin-bottom: 3vh
  }
  .modal-table-colum{
    display: flex;
    justify-content: space-between;
    flex: unset;
    width: 85%;
  }
  .invitation-modal-mobile-header{
    display: flex;
    font-weight: 700;
    font-size: 1.1em
  }
  .mobile-table-row{
    justify-content: space-between;
    width: 95%;
  }
  .mobile-table-colum{
    width: 30%;
  }
  .desktop{display: none;}
}

@media only screen and (max-width: 500px) {
  .modal-mask{font-size: 3vw;}
}

    @import '@/sass/commons/_variables';
    .actions {
        display: flex;
        border: none;
        margin-left: 5px;
        a {
            margin-right: 5px !important;
        }
    }
    .invitation {
        .fa-check-circle {
            color: $ternary-color;
            margin-left: 5px;
        }
    }
    .canceled {
        i {
            color: $brand-danger;
        }
    }
    .waiting {
        i {
            color: $yellow2;
        }
    }
    .confirmed {
        i {
            color: $ternary-color;
        }
    }
    .error {
        position: absolute;
        color: $lower-red;
    }
    i.remove-btn {
        color: $lower-red !important;
        font-size: 15px;
        cursor: pointer;
    }
</style>
