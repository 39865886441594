<template>
  <div class="page-containear">
    <div class="page-route-select">
      <router-link
        to="/cliente/monitores/cotacoes"
        class="page-route-select-text"
      >
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">Nova compra / Requisição</div>
    </div>
    <div class="page-title">Nova compra / Requisição</div>
    <div class="new-page__actions-container">
      <div class="new-page-inputs-left">
        <StandardInput
          class="standard-input-width"
          :title="'Tipo da Requisição'"
          :action="setTypesValue"
          :type="'select'"
          :list="typesArray"
          :placeholder="'Selecione o tipo'"
          :isRequired="true"
        />
        <StandardInput
          class="standard-input-width"
          :title="'Empresa'"
          :action="setStoresValue"
          :type="'select'"
          :list="storesArray"
          :placeholder="'Selecione a empresa'"
          :isRequired="true"
          v-if="
            !quotation.type || quotation.type.id != 'REQUEST_WITH_PRE_ORDERS'
          "
        />

        <StandardInput
          class="standard-input-width"
          :title="'Fornecedor'"
          :action="setProvider"
          :type="'select'"
          :list="providers"
          :placeholder="'Selecione um fornecedor'"
          :isRequired="true"
          v-else
        />
      </div>

      <StandardInput
        v-if="!quotation.type || quotation.type.id != 'REQUEST_WITH_PRE_ORDERS'"
        class="page-category-filter"
        :title="'Categoria'"
        :list="categoriesArray"
        :action="setCategoriesValue"
        :type="!quotation.store || !quotation.type ? 'disabled' : 'select'"
        :placeholder="'Selecione uma categoria'"
      />
      <div
        v-if="quotation.type && quotation.type.id == 'REQUEST_WITH_PRE_ORDERS'"
        class="newPage-supliers-itens-container"
      >
        <span
          class="material-icons-outlined"
          style="color: orange; cursor: pointer"
          >check_box_outline_blank</span
        >
        Carregar itens do fornecedor
      </div>

      <div class="new-page-inputs-rigth">
        <Button
          class="action-buttons-width action-buttons-width-half"
          title="Enviar"
          :text="'Enviar'"
          :iconName="'email'"
          :disabled="!valid_req()"
          :action="open_modal"
        />
        <Button v-if="can('PRODUCT', 'UPDATE')"
          class="action-buttons-width action-buttons-width-half"
          title="Adicionar produto"
          :text="'Adicionar Produto'"
          :iconName="'add'"
          :disabled="!quotation.store || !quotation.type"
          :action="
            () => {
              show_add_product_modal = !show_add_product_modal;
              showAddProductModal = true;
            }
          "
        />
      </div>

      <Button
        v-if="!isInWebView"
        class="action-buttons-width"
        title="Limpar filtros"
        :text="'Limpar Filtros'"
        :iconName="'delete'"
        :disabled="!quotation.store || !quotation.type"
        :action="clear_filters"
      />
    </div>
    <div v-if="isInWebView" class="webViewButtons">
      <Button
        class="action-buttons-width"
        title="ler código"
        :text="'Ler código'"
        :iconName="'qr_code_2'"
        :disabled="!quotation.store || !quotation.type"
        :action="readBarcodeOnReactNative"
      />
      <Button
        class="action-buttons-width"
        title="Limpar filtros"
        :text="'Limpar Filtros'"
        :iconName="'delete'"
        :disabled="!quotation.store || !quotation.type"
        :action="clear_filters"
      />
    </div>
    <div class="new-page__search-container">
      <StandardInput
        v-if="quotation.type"
        class="standard-input-width"
        :title="'Produto'"
        :action="setValue"
        :width="'38%'"
        :type="'text'"
        :placeholder="'Buscar produto'"
      />
      <div class="new-page__selected-checkbox-wrapper">
        <cc-checkbox
          style="margin-top: 6px"
          :disabled="!quotation.store || !quotation.type"
          :label="'Apenas itens pedido direto'"
          :checked="only_direct_order == 1 ? true : false"
          @change="(evt) => (only_direct_order = evt.target.checked)"
        />
      </div>
      <StandardInput
        v-if="quotation.type && quotation.type.id == 'REQUEST_WITH_PRE_ORDERS'"
        class="standard-input-width"
        :title="'Categoria'"
        :width="'25%'"
        :list="categoriesArray"
        :action="setCategoriesValue"
        type="select"
        :placeholder="'Selecione uma categoria'"
      />

      <div class="new-page__selected-wrapper">
        <div class="new-page__actions-title-wrapper">
          <span class="new-page__actions-title"
            >{{ count() }} itens digitados</span
          >
        </div>
        <div class="new-page__selected-checkbox-container">
          <div class="new-page__selected-checkbox-wrapper">
            <cc-checkbox
              style="margin-top: 6px"
              :disabled="!quotation.store || !quotation.type"
              :label="'Selecionar os itens digitados'"
              :checked="only_typed_filter == true"
              @change="(evt) => {test(evt.target.checked)}"
            />
          </div>
          <div class="new-page__selected-checkbox-wrapper">
            <cc-checkbox
              @click.prevent="clear()"
              style="margin-top: 6px"
              :label="'Remover itens digitados'"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="page-selected-filters-containear">
      <div v-if="product_name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ product_name }}</div>
        <span
          @click="setProductValue('')"
          class="material-icons page-selected-filters-icon"
          >close</span
        >
      </div>
      <div v-if="quotation.categoria" class="page-selected-filters">
        <div class="page-selected-filters-text">
          {{ quotation.categoria.cat_descricao }}
        </div>
        <span
          @click="setCategoryValue('')"
          class="material-icons page-selected-filters-icon"
          >close</span
        >
      </div>
    </div>

    <no-data-available
      v-if="products.length == 0 && !is_loading"
      :text="'Nenhum produto encontrado'"
    />
    <cc-loader-full v-if="is_loading" />

    <div
      v-if="
        quotation.store &&
        quotation.type &&
        quotation.type.id != 'REQUEST_WITH_PRE_ORDERS'
      "
    >
      <div class="page-table-container">
        <div>
          <div class="page-table-header">
            <div class="page-table-header-text quantity-width">Quantidade</div>
            <div class="page-table-header-text brand-width">Marcas</div>
            <div class="page-table-header-text product-width">Produto</div>
            <div class="page-table-header-text package-width">Embalagem</div>
            <div class="page-table-header-text code-width">Código</div>
          </div>
          <div
            v-for="(product, idx) in products"
            :key="'prod-' + product.pro_id"
            :class="{
              active: product.focused == true,
              'page-table-line-special': idx % 2 != 0,
            }"
          >
            <div class="page-table-row-containear item-card-mobile">
              <div class="page-table-row">
                <div
                  class="mobile new-page-mobile-header header-card-item-mobile"
                  style="text-align: left; width: 100%"
                >
                  {{
                    product.pro_descricao
                      ? product.pro_descricao.toUpperCase()
                      : "--"
                  }}
                </div>
                <!-- mobile -->
                <div
                  class="mobile"
                  style="
                    width: 100%;
                    justify-content: flex-start;
                    padding: 0 10px;
                    /* display: flex; */
                    /* background-color: red; */
                    /* margin-top: -0.8vh; */
                  "
                >
                  <div style="margin-right: 4px">Código:</div>
                  {{ product.pro_ean }}
                  <div style="margin-left: 15px; margin-right: 4px">
                    Embalagem:
                  </div>
                  {{
                    product.pro_generico
                      ? product.pro_unidade_compra
                      : product.pro_embalagem
                  }}
                  <i
                    class="far fa-edit"
                    @click="edit_wrap(product)"
                    v-if="can('PRODUCT', 'UPDATE') && product.pro_generico == 0"
                  />
                </div>
                <div
                  style="padding: 0 10px"
                  class="page-table-desc-column quantity-width"
                >
                  <!-- <div class="new-page-mobile-header">Quantidade</div> -->
                  <div class="desktopView" style="align-items: center">
                    <input
                      min="0"
                      type="number"
                      :data-idx="idx"
                      ref="product-amount"
                      class="product-amount"
                      :class="{
                        active: getStatus(product)
                      }"
                      v-model.number="product.new_amount"
                      @focus="is_focused(idx, product)"
                      @blur="process_event($event, product)"
                      v-on:keydown="process_event($event, product)"
                      :key="'qtd' + product.prod_id"
                    />
                    <div
                      class="quantity-input-icon"
                      v-on:click="addValue(product)"
                    >
                      <span class="material-icons-outlined"> add </span>
                    </div>
                  </div>

                  <!-- mobile -->

                  <div
                    v-if="product.new_amount != null"
                    class="mobile"
                    style="
                      font-size: 0.9rem;
                      flex-direction: column;
                      color: rgba(42, 42, 223, 0.993);
                    "
                  >
                    <div class="new-page-mobile-header">Quantidade:</div>
                    <div style="display: flex; align-items: center; gap: 1vw">
                      <!-- <i
                        class="far fa-edit"
                        @click="edit_wrap(product)"
                        v-if="product.pro_generico == 0"
                      /> -->
                      {{ product.new_amount }}
                    </div>
                  </div>
                  <div
                    v-if="product.estoque != null"
                    class="mobile"
                    style="
                      font-size: 0.9rem;
                      flex-direction: column;
                      color: rgba(42, 42, 223, 0.993);
                    "
                  >
                    <div class="new-page-mobile-header">Estoque:</div>
                    <div style="display: flex; align-items: center; gap: 1vw">
                      <!-- <i
                        class="far fa-edit"
                        @click="edit_wrap(product)"
                        v-if="product.pro_generico == 0"
                      /> -->
                      {{ product.estoque }}
                    </div>
                  </div>

                  <!-- mobile -->
                  <div></div>
                  <!-- <button
                    @click="showMarcas(product)"
                    :disabled="product.pro_generico == 0"
                    class="mobile brand-button-modal"
                  >
                    Ver Marcas
                  </button> -->

                  <button
                    @click="showModalInputs(product)"
                    class="mobile cart-button-modal"
                  >
                    <span class="material-icons">add_shopping_cart</span>
                  </button>
                </div>
                <!-- desktop -->
                <div class="desktop page-table-desc-column brand-width">
                  <div class="new-page-mobile-header">Marcas</div>
                  <button
                    @click="showMarcas(product)"
                    :disabled="product.pro_generico == 0"
                    class="brand-button-modal"
                  >
                    Marcas
                  </button>
                </div>

                <!-- desktop -->
                <div
                  class="desktop page-table-desc-column product-width column-divider"
                >
                  <div class="new-page-mobile-header">Produto</div>
                  {{
                    product.pro_descricao
                      ? product.pro_descricao.toUpperCase()
                      : "--"
                  }}
                </div>
                <!-- desktop -->
                <div
                  class="desktop page-table-desc-column package-width column-divider"
                >
                  <div class="new-page-mobile-header">Embalagem</div>
                  <div style="display: flex; align-items: center; gap: 1vw">
                    <i
                      class="far fa-edit"
                      @click="edit_wrap(product)"
                      v-if="can('PRODUCT', 'UPDATE') && product.pro_generico == 0"
                    />
                    {{
                      product.pro_generico
                        ? product.pro_unidade_compra
                        : product.pro_embalagem
                    }}
                  </div>
                </div>
                <!-- desktop -->
                <div class="desktop page-table-desc-column code-width">
                  <div class="new-page-mobile-header">Código</div>
                  {{ product.pro_ean }}
                </div>

                <!-- mobile -->
                <div class="mobile page-table-mobile-row"></div>
                <!-- <div class="page-table-desc-column brand-width">
						<div class="new-page-mobile-header">Marcas</div>
						<button @click="showMarcas(product)" :disabled="product.pro_generico == 0" class="brand-button-modal">
						Marcas
						</button>
					</div> -->
                <!-- <div class="page-table-desc-column product-width column-divider">
								<div class="new-page-mobile-header">Produto</div>
								{{ product.pro_descricao ? product.pro_descricao.toUpperCase() : '--' }}
							</div>
							<div class="page-table-desc-column package-width column-divider">
								<div class="new-page-mobile-header">Embalagem</div>
								<div style="display: flex; align-items: center;">
									<i class="far fa-edit" @click="edit_wrap(product)" v-if="product.pro_generico == 0"/>
									{{ product.pro_generico ? product.pro_unidade_compra : product.pro_embalagem }}
								</div>

							</div>
							<div class="page-table-desc-column code-width">
								<div class="new-page-mobile-header">Código</div>
								{{ product.pro_ean }}
							</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DirectOrderTab
      :products="products"
      :temps="temp_map"
      :load_temp_products="load_temp_products"
      @onupdate="(val) => (direct_purchase_products_count = val)"
      v-if="
        quotation.type &&
        quotation.type.id == 'REQUEST_WITH_PRE_ORDERS' &&
        products &&
        provider_id
      "
    />

    <cc-pagination
      v-show="quotation.store"
      classes="orange"
      :items_by_page_value="items_by_page_value"
      :total="total_items"
      :page="page"
      :page_limit="page_limit"
      v-on:change="updatePage($event)"
    >
    </cc-pagination>

    <new-request-modal
      id="modal"
      :products="selected_products()"
      :quotation="quotation"
      @finish="success()"
      :payment_types="payment_types"
      v-if="showNewRequestModal"
      :closeDialog="closeNewRequestModal"
    />

    <new-direct-request-modal
      id="modal"
      :products="selected_products()"
      :quotation="quotation"
      :provider_id="provider_id"
      :providerName="providerName"
      @finish="success()"
      :payment_types="payment_types"
      v-if="show_direct_request_modal"
      :closeDialog="closeNewDirectRequestModal"
    />
    <add-product-modal
      id="modal"
      @add_product="add_temp_product"
      v-if="show_add_product_modal && showAddProductModal"
      :closeDialog="closeAddProductModal"
      :to_request="true"
    />
    <cc-product-brands
      id="modal"
      @add_product="add_temp_product"
      v-if="brands && showEditBrandsModal"
      :closeDialog="closeEditBrandsModal"
      :brands="brands"
      :product="current_product"
      @close="brands = null"
    />
    <cc-product-inputs
      id="modal"
      v-if="showProductInputsModal && ProductInputsModal"
      :product="ProductInputsModal"
      :closeDialog="closeProductInputsModal"
      :add_temp_product="add_temp_product"
      :quotation="this.quotation"
      :allOnlyStock="allOnlyStock"
      :allOnlyStockChange="handleSwitchChange"
    />
    <cc-product-wrap
      v-if="open_edit_wrap"
      :product="{
        ...current_product,
        embalagem: current_product.pro_embalagem,
        idProduto: current_product.pro_id,
      }"
      @close="update_wrap"
    />
  </div>
</template>


<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import V2RequestsService from "@/services/v2/requests.service";
import TemporaryRequestService from "@/services/v3/requests/temporary.service";
import OrderService from "@/services/OrderService";
import ProductService from "@/services/ProductService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import NewRequestModal from "@/modals/new-request/new-request.modal";
import NewDirectRequestModal from "@/modals/new-direct-request/new-direct-request.modal";
import AddProductModal from "@/modals/add-product/add-product.modal";
import ProductBrandsModal from "@/modals/product-brands/product-brands.modal";
import ProductInputsModal from "@/modals/product-modal-input-request/product-inputs.modal";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import StorageService from "@/services/local-storage.service";
import ProductWrapModal from "@/modals/product-wrap/product-wrap.modal";
import { perm_mixin } from "@/mixins/permission.mixin";
import Button from "@/components/ui/buttons/Standard-Button.vue";
import StandardInput from "@/components/ui/inputs/StandardInput.vue";
import { mapGetters } from "vuex";
import DirectOrderTab from "./DirectOrderTab.vue";
import ProviderService from "@/services/v3/provider.service";

export default {
  mixins: [typingRemoteRequestControlMixin, perm_mixin],
  components: {
    ccPagination: Pagination,
    "cc-product-wrap": ProductWrapModal,
    "cc-product-brands": ProductBrandsModal,
    "cc-product-inputs": ProductInputsModal,
    "new-request-modal": NewRequestModal,
    "new-direct-request-modal": NewDirectRequestModal,
    "add-product-modal": AddProductModal,
    Button,
    StandardInput,
    DirectOrderTab,
  },
  data() {
    return {
      showAddProductModal: true,
      showNewRequestModal: false,
      show_direct_request_modal: false,
      page_limit: screen.width>800?11:5,
      is_loading: false,
      CONSTANTS: CONSTANTS,
      items_by_page_value: 30,
      page: 1,
      total_items: undefined,
      product_name: "",
      only_typed_filter: false,
      only_direct_order: false,
      provider: {},
      products: [],
      force_reload_products: false,
      payment_types: [],
      temp_map: new Map(),
      quotation: {
        loja: null,
        categoria: null,
        type: null,
      },
      open_edit_wrap: false,
      show_add_product_modal: false,
      stores: [],
      storesArray: [],
      categories: [],
      categoriesArray: [{ text: "TODOS", value: undefined }],
      providers: [],
      psvc: new ProviderService(),
      service: new QuotationService(),
      v2_service: new V2RequestsService(),
      product_service: new ProductService(),
      orderService: new OrderService(),
      tmp_svc: new TemporaryRequestService(),
      types: [],
      typesArray: [],
      buscaProduto: "",
      categoria: "",
      comprado: null,
      comprados: false,
      naoComprados: false,
      seletecdValor: "",
      arrayTableGet: null,
      arrayCategoria: null,
      arraySubcategoria: null,
      arrayEmbalagem: null,
      categoriaModal: "",
      proEan: "",
      embalagemModal: "",
      codigoModal: "",
      tituloModal: "",
      produtoModal: "",
      genericoModal: 0,
      unidadeModal: "",
      isGenerico: false,
      subcategoriaModal: "",
      errorProdutoModal: false,
      errorCodigoModal: false,
      providerName: '',
      errorUnidadeModal: false,
      errorEmbalagemModal: false,
      errorQtdEmbalagemModal: false,
      errorCategoriaModal: false,
      errorSubcategoriaModal: false,
      isAdicional: false,
      direct_purchase_products_count: 0,
      prodId: "",
      serverMsg: "",
      qtdEmbalagemModal: "",
      botaoCadastrar: false,
      arrayMarcas: [],
      nomeProdutoModalMarca: "",
      buscaMarca: "",
      marcaPreferencial: null,
      brands: null,
      provider_id: null,
      current_product: null,
      breadcrumbs: [{ name: "Nova Compra", route: "cliente-new-request" }],
      showEditBrandsModal: true,
      barcode: null,
      isInWebView: false,
      showProductInputsModal: false,
      ProductInputsModal: null,
      req_type: "DEFAULT",
      allOnlyStock: false,
    };
  },
  watch: {
    provider: function () {
      this.loadProviderProducts();
    },
    "quotation.categoria": function () {
      this.page = 1;
      this.load_products();
      this.$forceUpdate();
    },
    // only_typed_filter: function (checked) {
    //   this.page = 1;
    //   console.log(checked);
    //   this.load_typed_products(checked);
    // },
    only_direct_order: function (checked) {
      this.load_direct_order(checked);
    },
  },
  mounted() {
    window.addEventListener("message", this.handleWebViewMessage);
    this.isInWebView = typeof window.ReactNativeWebView !== "undefined";
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleWebViewMessage);
  },
  methods: {
    handleTeste(item) {
      console.log(item);
    },
    test(checked){
      this.page = 1;
      this.only_typed_filter = checked
      this.load_typed_products(checked);
    },
    handleSwitchChange(val) {
      this.allOnlyStock = val;
    },
    addValue(product) {
      if(!product.new_amount) product.new_amount = 1
      else product.new_amount++;
      this.save_temp(product);
    },
    valid_req() {
      return (
        this.quotation.type &&
        (this.quotation.store ||
          (this.provider_id &&
            this.quotation.type.id == "REQUEST_WITH_PRE_ORDERS"))
      );
    },
    closeAddProductModal() {
      this.show_add_product_modal = false;
      this.load_products();
    },
    setProvider(value) {
      this.provider_id = value.id;
      this.providerName = value.name
    },
    setProductValue(value) {
      this.product_name = value;
      setTimeout(() => {
        this.load_products();
      }, 600);
    },
    setValue(value) {
      this.product_name = value;
      this.load_products((this.page = 1));
    },
    setStoresValue(value) {
      this.quotation.store = value;
      this.check_type();
    },
    setTypesValue(value) {
      this.quotation.type = value;
      if (value.name == "Pedido Direto") this.req_type = "REQUEST_WITH_PRE_ORDERS";
      else this.req_type = "DEFAULT";
      this.check_type();
    },
    setCategoriesValue(value) {
      this.quotation.categoria = value;
      this.check_type();
    },
    setCategoryValue(value) {
      this.quotation.categoria = value;
    },
    closeNewRequestModal: function () {
      this.showNewRequestModal = false;
    },
    closeNewDirectRequestModal() {
      this.show_direct_request_modal = false;
    },
    edit_wrap(product) {
      this.open_edit_wrap = true;
      product.descricaoProduto = product.pro_descricao;
      this.current_product = product;
    },
    update_wrap(wrap) {
      this.open_edit_wrap = false;
      this.$set(this.current_product, "pro_embalagem", wrap.wrap);
    },
    clear_filters() {
      this.only_typed_filter = false;
      this.only_direct_order = false;
      this.quotation.categoria = null;
      this.product_name = null;
    },
    clear() {
      this.$swal
        .fire({
          title: "Confirma remoção dos produtos selecionados?",
          text: "Os produtos digitados por serão removidos.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, continuar!",
        })
        .then((result) => {
          if (result.value) {
            this.clear_temp_products(() => {
              this.load_products();
              this.direct_purchase_products_count = 0
              this.$swal
                .fire(
                  "Seleção Removida!",
                  "Digitações removidas com sucesso!",
                  "success"
                )
                .then((result) => {});
            });
          }
        });
    },
    check_type(val) {
      if (val && val.id) this.quotation.type = val;
      if (this.quotation.type && this.quotation.type.id) {
        this.load_products();
      }
    },
    count() {
      if (this.quotation?.type?.id == "REQUEST_WITH_PRE_ORDERS") {
        return this.direct_purchase_products_count;
      }
      return this.temp_map.size;
    },
    build_products() {
      return this.selected_products().map((p) =>
        Object.assign(
          {},
          {
            quantidade: p.quantidade,
            idProduto: p.pro_id,
            estoque: p.estoque,
            observacao: "",
          }
        )
      );
    },
    open_modal() {
        //! impedir cotacao sem quantidade em algum produto digitado
      if (this.quotation.type.id === "DEFAULT") {
        let verifyProds = this.build_products()
        let showError = verifyProds.some(item=>item.quantidade <=0)
        if(showError){
            this.$swal.fire(
              "Ops!",
              "Você não pode enviar 1 item com quantidade 0",
              "error"
            );
            return
        }
      }


      if (
        this.count() == 0 &&
        this.quotation.type.id != "REQUEST_WITH_PRE_ORDERS"
      ) {
        this.$swal.fire(
          "Ops!",
          "Escolha ao menos 1 item para continuar.",
          "error"
        );
      } else if (this.quotation.type.id == "REQUEST_WITH_PRE_ORDERS") {
        this.show_direct_request_modal = !this.show_direct_request_modal;
      } else if (this.quotation.type.id == "HEADQUARTER") {
        this.$swal
          .fire({
            title: "Confirma envio da requisição para central?",
            text: "Sua requisição será enviada para central.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, continuar!",
          })
          .then((result) => {
            if (result.value) {
              this.is_loading = true;

              var payload = {
                produtos: this.build_products(),
                idLoja: this.quotation.store
                  ? this.quotation.store.loj_id
                  : null,
              };
              this.service
                .inserircotacaocentral(payload)
                .then((response) => response.data)
                .then((data) => {
                  this.success();
                })
                .finally(() => (this.is_loading = false))
                .catch((error) => {
                  this.loading = false;
                  ErrorHandlerService.handle(error, this.$store);
                });
            }
          });
      } else if (this.quotation.type.id == "INTERNAL") {
        this.$swal
          .fire({
            title: "Confirma registro de compra interna?",
            text: "Sua compra será registrada internamente e NÃO será enviada aos fornecedores.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, continuar!",
          })
          .then((result) => {
            if (result.value) {
              this.is_loading = true;

              var payload = {
                produtos: this.build_products(),
                cot_idLoja: this.quotation.store
                  ? this.quotation.store.loj_id
                  : null,
                for_id: this.provider ? this.provider.for_id : null,
                CTYPE: this.quotation.type.id,
                entrega: StorageService.tpc(),
              };
              this.v2_service
                .create(payload)
                .then((response) => response.data)
                .then((data) => {
                  this.success();
                })
                .finally(() => (this.is_loading = false))
                .catch((error) => {
                  this.$swal.fire(
                    "Ops! um Erro ocorreu!",
                    error.response,
                    "error"
                  );
                  this.loading = false;
                  ErrorHandlerService.handle(error, this.$store);
                });
            }
          });
      } else {
        this.showNewRequestModal = !this.showNewRequestModal;
      }
    },
    selected_products() {
      let products = [];
      this.temp_map.forEach((p) => {
        if (p.quantity>=0 || p.estoque>=0) products.push(p);
      });
      return products.map((p) => {
        return {
          quantidade: p.quantity,
          pro_id: p.product_id,
          estoque: p.estoque,
          observacao: "",
        };
      });
    },
    success() {
      let message = "Sua compra/cotação foi enviada com sucesso.";
      this.clear_temp_products(() => {
        this.products.forEach((p) => (p.new_amount = 0));
        this.$swal.fire("Publicado!", message, "success").then((result) => {
          this.$router.push({
            name: "monitors-page",
            params: { tab: "cotacoes" },
          });
        });
      });
    },
    itens_amount() {
      return this.products.filter((p) => p.new_amount > 0).length;
    },
    clear_temp_products(callback) {
      this.orderService
        .clearRequestTempProducts(this.req_type )
        .then(() => {
          if (callback) callback();
          this.temp_map = new Map();
        });
    },
    getStatus(product){
        return product.new_amount == 0 || product.new_amount > 0
    },
    save_temp(prod) {
      let data = {
        product_id: prod.pro_id,
        ean: prod.ean ? prod.ean : prod.pro_ean,
        quantity: prod.new_amount,
        estoque: prod.estoque ? prod.estoque : 0,
      };
      //   if(data.quantity ==0 && data.estoque ==0){
      //     data.forEach((tmp) => {
      //     this.temp_map.set(tmp.ean, tmp);
      //     if (this.products) {
      //       this.products.forEach((p) => {
      //         if (tmp.ean == p.pro_ean) {
      //             console.log('--',tmp)
      //           this.$set(p, "new_amount", tmp.quantity);
      //           this.$set(p, "estoque", tmp.estoque);
      //           this.$forceUpdate();
      //         }
      //       });
      //     }
      //   });
      //   }

      const desktop = screen.width>800
      if ((desktop || this.quotation.type.id == "DEFAULT") && data.quantity != 0 && !data.quantity) return
      if (desktop && data.quantity == 0 ) {
        this.is_loading = true;
        this.tmp_svc.remove(data).then(()=>{
          this.load_temp_products()
        })
        return
      }
      this.is_loading = true;
      this.tmp_svc
        .send(data)
        .then((response) => response.data)
        .then((data) => {
          this.process_temp_products_on_screen(data);
          prod.changed = false;
          this.is_loading = false;
          this.showProductInputsModal = false;
          this.$forceUpdate();
        })
        .catch(() => {
          prod.new_amount = null;
          this.temp_map.delete(prod.pro_ean);
          const notification = {
            type: "error",
            message: "Não foi possível salvar a quantidade!",
          };
          this.$store.dispatch("notification/add", notification);
          this.is_loading = false;
          this.$forceUpdate();
        });
    },
    add_temp_product(prod) {
      this.products
        .filter((p) => p.pro_ean == prod.ean)
        .forEach((p) => {
          this.$set(p, "new_amount", prod.new_amount);
          this.$set(p, "estoque", prod.estoque);
        });
      if(prod.ean) this.temp_map.set(prod.ean, {
        pro_id: prod.id,
        pro_ean: prod.ean,
        quantidade: prod.new_amount,
        estoque: prod.estoque,
        tipo: this.quotation.id,
      });

      this.save_temp(prod);
    },
    is_focused(idx, product) {
      let inputs = this.$refs["product-amount"];
      this.products.forEach((p) => (p.focused = false));
      if (inputs && document.activeElement == inputs[idx]) {
        this.$set(product, "focused", true);
        this.$forceUpdate();
      }
    },
    process_event(e, prod) {
      if (this.is_loading) return;
      let key = getKey(e);
      let idx = parseInt(e.target.dataset.idx);
      let inputs = this.$refs["product-amount"];
      inputs.sort((a, b) => {
        let pos1 = parseInt(a.dataset.idx);
        let pos2 = parseInt(b.dataset.idx);
        if (pos1 > pos2) return 1;
        if (pos2 > pos1) return -1;
        return 0;
      });

      let next_idx = idx + 1 == inputs.length ? 0 : idx + 1;
      let prev_idx = idx == 0 ? inputs.length - 1 : idx - 1;
      function getKey(e) {
        if (e.key) return e.key;
        let keyFromCode = String.fromCharCode(e.keyCode);

        if (keyFromCode) return keyFromCode;
        // add here the tricky keys that you use in your app
        if (e.keyCode === 13) return "Enter";
        if (e.keyCode === 16) return "Shift";
        if (e.keyCode === 8) return "Tab";
        // etc
      }
      if (key == "ArrowUp") {
        e.preventDefault();
        // if(prod.changed)this.save_temp(prod);
        inputs[prev_idx].focus();
        inputs[prev_idx].select();
      } else if (key == "ArrowDown") {
        e.preventDefault();
        // if(prod.changed)this.save_temp(prod)
        inputs[next_idx].focus();
        inputs[next_idx].select();
      } else if (key == "Enter" || key == "Tab") {
        e.preventDefault();
        if (prod.changed) this.save_temp(prod);
        inputs[idx].focus();
        inputs[idx].select();
      } else if (e.type == "blur") {
        e.preventDefault();
        if (prod.changed) this.save_temp(prod);
      } else {
        prod.changed = true;
      }
    },
    load_typed_products(only_typed = false) {
      let result = [];

      if (this.only_typed_filter) {
        this.load_temp_products().then((data) => {
          this.temp_map.forEach((k, v) => {
            result.push(k.ean);
          });
          if (result.length == 0) result = null;
          this.load_products(result);
        });
      } else {
        this.products = [];
        this.load_products();
      }
    },
    load_direct_order(only_direct = false) {
      let result = [];
      this.only_direct_order = only_direct;

      if (this.only_direct_order) {
        this.load_temp_products().then((data) => {
          this.temp_map.forEach((k, v) => {
            result.push(k.ean);
          });
          if (result.length == 0) result = null;
          this.load_products(result);
        });
      } else {
        this.products = [];
        this.load_products();
      }
    },
    updatePage(new_page) {
      this.page = new_page;
      let result = [];
      // if(this.only_typed_filter) {
      //     this.load_temp_products().then(data => {
      //         this.temp_map.forEach((k, v) => { result.push(k.ean) })
      //         if(result.length == 0)result = null;
      //         this.load_products(result)
      //     })
      // } else {
      this.load_products();
      // }
    },
    load_stores() {
      this.service
        .getStoresClient()
        .then((response) => response.data)
        .then((data) => {
          this.stores = data.requisitantes;
          this.stores.forEach(
            (s) => (s.loj_nomeFantasia = s.loj_nomeFantasia.toUpperCase())
          );
          this.stores.forEach((el) => {
            this.storesArray.push({
              text: el.loj_nomeFantasia,
              value: el,
            });
          });
        });
    },
    load_categories() {
      this.service
        .getCategorias()
        .then((response) => response.data)
        .then((data) => {
          this.categories = data.categorias;
          this.categories.forEach((el) => {
            this.categoriesArray.push({
              text: el.cat_descricao,
              value: el,
            });
          });
        });
    },
    load_temp_products() {
      if (this.quotation?.type?.id == "REQUEST_WITH_PRE_ORDERS") {
        return;
      }

      return this.tmp_svc
        .load()
        .then((response) => response.data)
        .then((data) => {
          this.is_loading = false;
          this.temp_map = new Map();
          this.process_temp_products_on_screen(data);
        });
    },
    process_temp_products_on_screen(data) {


      if (this.products) {
        this.products.forEach((p) => {
          const tmpMatch = data.find((tmp) => tmp.ean === p.pro_ean);
          if (tmpMatch) {
            this.$set(p, "new_amount", tmpMatch.quantity);
            this.$set(p, "estoque", tmpMatch.estoque);
            this.$forceUpdate();
          } else {
            this.$delete(p, "new_amount");
            this.$delete(p, "estoque");
            this.$forceUpdate();
          }
        });
      }

      data.forEach((tmp) => {
        this.temp_map.set(tmp.ean, tmp);
      });
      // -------- old code
      //   data.forEach((tmp) => {
      //     this.temp_map.set(tmp.ean, tmp);
      //     if (this.products) {
      //       this.products.forEach((p) => {
      //         console.log(tmp.ean ,'--',p.pro_ean)
      //         if (tmp.ean == p.pro_ean) {
      //           console.log("--", tmp);
      //           this.$set(p, "new_amount", tmp.quantity);
      //           this.$set(p, "estoque", tmp.estoque);
      //           this.$forceUpdate();
      //         }
      //       }
      //       );
      //     }
      //   });
      // -------- /old code
    },
    loadProviderProducts(pro_ids) {
      this.is_loading = true;

      let payload = {
        ids: pro_ids,
        page: this.page,
        product_name: this.product_name,
      };
      this.orderService
        .getProviderProductsForCurrentClient(
          this.provider ? this.provider.for_id : null,
          payload
        )
        .then((response) => response.data)
        .then((data) => {
          setTimeout(() => {
            this.total_items = data.total;
          }, 1000);
          this.products = data.data
            .filter((p) => p.product)
            .map((p) => {
              p.product.preco_unitario = p.Preco;
              return p.product;
            });
          this.load_temp_products();
          this.is_loading = false;
        })
        .catch((err) => {
          this.products = [];
          this.is_loading = false;
          ErrorHandlerService.handle(err, this.$store);
        });
    },
    load_products(pro_eans = null) {
      if (!(pro_eans instanceof Array)) {
        pro_eans = null;
      }

      // if (!pro_eans) this.only_typed_filter = false;
      const callback = (data) => {
        if (data) {
          setTimeout(() => this.total_items = data.produtos.total, 500);
          this.products = data.produtos.data;
        }
        this.products.forEach((p) => (p.new_amount = null));
        this.products.forEach((p) => (p.estoque = null));
        this.products.forEach((p) => (p.changed = false));
        this.load_temp_products();
        this.is_loading = false;
        this.$forceUpdate();
      };

      this.is_loading = true;
      this.$forceUpdate();

      // if (this.force_reload_products) {
      //   this.products = [];
      //   this.force_reload_products = false;
      // }

      // this.cancelSearch(() => {
      //   this.is_loading = true;
      // });

      this.service
        .getProdutosClientePorPagina(
          {
            categoria: this.quotation.categoria
              ? this.quotation.categoria.cat_id
              : null,
            nomeproduto: this.product_name,
            page: this.page,
            items_by_page_value: this.items_by_page_value,
            deleted: 0,
            with_brands: 1,
            only_typed: this.only_typed_filter ? 1 : 0,
            pro_pedido_direto: this.only_direct_order ? 1 : 0,
          },
          // this.cancelSource.token
        )
        .then((response) => response.data)
        .then(callback)
        .catch((error) => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    showMarcas(product) {
      this.is_loading = true;
      this.product_service.get_product_brands(product.pro_id).then((data) => {
        this.brands = data.data.produtos;
        this.current_product = product;
        this.showEditBrandsModal = true;
        this.is_loading = false;
      });
    },
    showModalInputs(product) {
      this.ProductInputsModal = product;
      this.showProductInputsModal = true;
      //   this.is_loading = true;
      //   this.product_service.get_product_brands(product.pro_id).then((data) => {
      //     this.brands = data.data.produtos;
      //     this.current_product = product;
      //     this.showEditBrandsModal = true;
      //     this.is_loading = false;
      //   });
    },
    load_providers() {
      this.loading = true;
      return this.psvc.load_providers().then((response) => {
        return response.data;
      });
    },
    load_types() {
      let types = [{ name: "Enviar para Central ", id: "HEADQUARTER" }];
      if (this.perms.length == 0) {
        types.push({ name: "Cotacao", id: "DEFAULT" });
        if(CONSTANTS.PROJECT_ENV != 'prod')types.push({ name: "Pedido Direto", id: "REQUEST_WITH_PRE_ORDERS" });
      } else {
        this.perms.forEach((element) => {
          if (element.action == "CREATE" && element.resource == "REQUEST")
            types.push({ name: "Cotacao", id: "DEFAULT" });
          if (CONSTANTS.PROJECT_ENV != 'prod' && element.action == "CREATE" && element.resource == "REQUEST")
             types.push({
               name: "Pedido Direto",
               id: "REQUEST_WITH_PRE_ORDERS",
             });
        });
      }
      this.types = types;
      this.types.forEach((el) => {
        this.typesArray.push({
          text: el.name,
          value: el,
        });
      });
    },
    closeEditBrandsModal: function () {
      this.showEditBrandsModal = false;
    },
    closeProductInputsModal: function () {
      this.showProductInputsModal = false;
    },
    readBarcodeOnReactNative() {
      //    alert('vue')
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "openCamera" })
      );
    },
    handleWebViewMessage(event) {
      try {
        const data = JSON.parse(event.data);

        if (data.type === "barcodeScanned") {
          this.barcode = data.code;
          this.setValue(data.code);
          //   alert(data.code)
        }
      } catch (error) {
        // console.error("Erro ao fazer o parse do JSON:", error);
      }
    },
  },
  computed: {
    ...mapGetters({ perms: "user/perms" }),
  },
  created() {
    this.load_providers().then(
      (data) =>
        (this.providers = data.map((p) => {
          return { text: p.name, value: p };
        }))
    );
    this.load_stores();
    this.load_categories();
    this.load_products();
    this.$store.dispatch("set_breadcrumbs", this.breadcrumbs);
    this.load_types();
  },
};
</script>

<style lang="scss" scoped>
.page-route-select {
  display: flex;
  align-items: center;
}
.page-containear {
  font-size: 1vw;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.1em;
  color: #ababab;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--secondary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.8em;
  letter-spacing: 0.15px;
  color: #605f5f;
  margin-top: 45px;
}
.new-page__actions-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 2em 0em;
  margin-right: 2em;
}
.new-page__search-container {
  display: flex;
  align-items: flex-end;
  margin: 0em;
  justify-content: space-between;
  margin-right: 2em;
}
.new-page__actions-wrapper {
  margin-right: 1em;
  margin-top: 0.3em;
}
.new-page__selected-wrapper {
  margin-left: 1em;
}
.new-page__selected-checkbox-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.standard-input-width {
  width: 45%;
}
.new-page__selected-checkbox-wrapper {
  margin-right: 1em;
}
.new-page__actions-title {
  font-size: 1.19em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.action-buttons {
  margin-top: 2.5em;
}
.search-container {
}
.page-table-container {
  margin: 2em 0em;
  overflow-x: auto;
}
.page-table-header {
  background: #fffefc;
  border: 0.5px solid #e5e5e5;
  font-weight: 400;
  color: #605f5f;
  display: flex;
  padding: 0.4em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600;
  font-size: 1.3em;
  color: #505050;
}
.new-page-inputs-left {
  display: flex;
  justify-content: space-between;
  width: 38%;
}
.new-page-inputs-rigth {
  display: flex;
  justify-content: space-between;
  width: 25%;
  font-size: 0.9em;
}

.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.1em;
  color: #605f5f;
  align-items: center;
}
.column-divider {
  border-right: 0.5px solid #dde5e9;
}
.page-table-desc-column {
  padding: 0.2em 0.3em;
}
.quantity-width {
  display: flex;
  align-items: center;
  width: 10%;
}
.brand-width {
  width: 10%;
}
.product-width {
  flex: 1;
}
.package-width {
  width: 15%;
}
.code-width {
  width: 15%;
}
/* .page-table-column__quantity-input:focus {
  border: 1px solid var(--primary-color);
  outline: none;
} */
/* .page-table-column__quantity-input:active {
  background-color: #9fff9f73 !important;
  color: green !important;
} */
.quantity-input-icon {
  position: relative;
  left: -20px;
  width: 5vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 0px 5px 5px 0px;
  border-top: 1px solid #c2c2c3;
  border-bottom: 1px solid #c2c2c3;
  border-right: 1px solid #c2c2c3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.brand-button-modal {
  border: 1px solid #e5e5e5;
  text-transform: uppercase;
  color: var(--primary-color);
  font-size: 1.1em;
  font-weight: 600;
  border-radius: 5px;
  padding: 0.5em;
  background-color: #fff;
}
.cart-button-modal {
  color: var(--primary-color);
  span {
    font-size: 2rem;
  }
  padding: 5px 15px;
  border: none;
  font-weight: 600;

  background-color: transparent;
}
.new-page-mobile-header {
  display: none;
}
.brand-button-modal:disabled {
  color: #e5e5e5;
  border: 1px solid #e5e5e5;
  cursor: not-allowed;
}
.brand-button-modal:hover {
  border: 1px solid #e5e5e5;
  color: #fff;
  background-color: var(--primary-color);
}
button[disabled]:hover {
  background-color: #fff;
  color: #e5e5e5;
}
.customized-select {
  width: 20vw !important;
}
.customized-search {
  width: 41vw !important;
}
.page-selected-filters-containear {
  display: flex;
  margin-top: 14px;
}
.page-selected-filters {
  background: #fcf9f7;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 1.1em;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.page-table-line-special {
  background: #f7f7f7;
}

.action-buttons-width {
  margin-top: 20px;
}

.newPage-supliers-itens-container {
  display: flex;
  align-items: center;
  gap: 1vw;
  margin-bottom: 2.5vh;
  color: #6c757d;
}
.mobile {
  display: none;
}
.desktopView {
  display: flex;
}
.header-card-item-mobile {
  background-color: var(--primary-color-opacity);
  padding: 4px 8px;
  color: var(--primary-color);
  font-weight: 300;
}

@media only screen and (max-width: 800px) {
  .item-card-mobile {
    margin-right: 10px;
    border-radius: 8px;
    border: 1px solid #c7c7c7;
    overflow: hidden;
    margin-bottom: 5px;
  }
  .page-containear {
    font-size: 2vw;
  }
  .mobile {
    display: flex;
  }
  .desktopView {
    display: none;
  }
  .page-table-mobile-row {
    justify-content: space-between;
    width: 100%;
    padding: 0 0.3em;
  }
  .page-category-filter {
    width: 100%;
    margin-bottom: 2vh;
  }
  .new-page-inputs-left {
    width: 100%;
    align-items: flex-end;
    gap: 5vw;
    font-size: 0.9em;
  }
  .new-page-inputs-rigth {
    width: 100%;
    align-items: flex-end;
  }

  .new-page__actions-container {
    flex-direction: column;
    margin: 0;
    margin-right: 2em;
  }
  .new-page__actions-wrapper {
    margin-right: 0em;
    margin-top: 0em;
    margin: 0.5em auto;
  }
  .customized-select {
    width: 60vw !important;
  }
  .customized-search {
    width: 60vw !important;
  }
  .action-buttons {
    width: 60vw;
  }
  .new-page__search-container {
    flex-direction: column;
  }
  .product-amount {
    width: 15vw !important;
  }
  .quantity-input-icon {
    width: 8vw;
  }
  .brand-button-modal {
    /* width: 30vw; */
    font-size: 12px;
  }
  .standard-input-width {
    width: 100% !important;
  }
  .action-buttons-width {
    width: 100%;
    font-size: 1.5em !important;
    margin-top: 0px;
    margin-bottom: 1.5vh;
  }
  .action-buttons-width-half {
    width: 46%;
  }
  .page-table-header {
    display: none;
  }
  .page-table-row {
    font-size: 1.2em;
    flex-direction: column;
    gap: 10px;
    /* background-color: blue; */
    /* margin-bottom: 3vh; */
  }
  .page-table-desc-column {
    display: flex;
    justify-content: space-between;
    flex: unset;
    width: 100%;
    align-items: center;
  }
  .new-page-mobile-header {
    font-weight: 700;
    font-size: 1em;
    display: flex;
  }
  .new-page__selected-wrapper {
    width: 100%;
    margin-left: 0;
    margin-top: 1vh;
  }
  .desktop {
    display: none;
  }
  .webViewButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin: 0 25px 0 0;
  }
}

@media only screen and (max-width: 500px) {
  .page-containear {
    font-size: 3vw;
  }
}

@import "./new.page";
</style>

<style lang="scss" scoped>
@import "@/sass/commons/_variables";
.body {
  width: calc(97%) !important;
}
</style>
