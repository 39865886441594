<template>
    <div class="table-container">
        <table>
           <tr>
                <th style="width: 60%;">Nome</th>
                <th>Data cadastro</th>
                <th>Categoria</th>
            </tr>
            <tr class="product-text" v-for="(p, idx) in products" :key="idx">
                <td>{{ p.pro_descricao }}</td>
                <td v-if="p.pro_dataCadastro">{{  p.pro_dataCadastro | formatDateOnly }}</td>
                <td v-else>--</td>
                <td >{{ getCategory(p) }}</td>
            </tr> 
        </table>
        
    </div>
</template>

<script>
export default {
    name: 'ProductsTable',
    props: {
        products: {
            type: Array
        },
        categories:{
            type: Array
        }
    },
    methods: {
        getCategory(p) {
            const resp = this.categories.filter(c => c.id == p.cat_id)
            return resp.length > 0 ? resp[0].cat_descricao : '--'
        }
    },
}
</script>

<style lang="scss" scoped>
.table-container {
    height: 60vh;
    overflow: auto;
}

.product-text {
    font-weight: 400;
    font-size: 1.2em;
    color: #606060;
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.5vh;
}
</style>