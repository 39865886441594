<template>
    <div class="monitors">
        <div class="body">
            <div class="map-page-guide">Mapa de Fornecedores</div>
            <div class="map-page-title">Mapa de Fornecedores</div>
            <cc-loader-full v-if="loading"/>
            <StandardInput
                class="desktop"
                :title="'Estado'"
                :width="'25%'"
                :type="'select'"
                :action="(evt) => filter_state(evt)"
                :list="formatStatesList(statusStates)"/>
            <l-map
                v-if="!loading"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                style="height: 100%;z-index: 0;"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
            >
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-control class="map-controls">
                    <toggle-button
                        v-model="filters.related"
                        :color="{ checked: 'var(--primary-color)', unchecked: '#4267B2' }"
                        :font-size="12"
                        :height="30"
                        :sync="true"
                        @change="(evt) => control_filter('related', evt)"
                        :labels="{ checked: 'Meus Fornecedores', unchecked: 'Meus Fornecedores' }"
                        :width="toggle_width-10" />
                </l-control>
                <l-control class="map-controls">
                    <toggle-button
                        v-model="filters.plus"
                        :color="{ checked: 'var(--primary-color)', unchecked: '#4267B2' }"
                        :font-size="12"
                        :height="30"
                        :sync="true"
                        @change="(evt) => control_filter('plus', evt)"
                        :labels="{ checked: 'Parceiros Club', unchecked: 'Parceiros Club' }"
                        :width="toggle_width-35" />
                </l-control>
                <l-control class="map-controls">
                    <cc-input
                        placeholder="Fornecedor"
                        v-model="filters.name"
                        classes="'name'" />
                </l-control>
                <l-marker
                    :lat-lng="coords(prov.head_office)"
                    v-for="prov in valid_providers"
                    :key="prov.id">
                    <l-icon
                        class-name="provider-icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </l-icon>
                    <l-tooltip :options="{ permanent: false, interactive: true }">
                        <div>
                            <picture class="cover-img">
                                <img :src="prov.cover_url" alt="">
                                {{ prov.name }}
                            </picture>
                        </div>
                    </l-tooltip>
                </l-marker>
                <l-marker
                    :lat-lng="coords(store)"
                    v-for="store in stores"
                    :key="store.id">
                    <l-icon
                        class-name="store-icon">
                        <i class="fas fa-store-alt shadow"></i>
                    </l-icon>
                    <l-tooltip :options="{ permanent: false, interactive: true }">
                    <div @click="innerClick">
                        {{ store.name }}
                        <p v-show="showParagraph">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                        sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
                        Donec finibus semper metus id malesuada.
                        </p>
                    </div>
                    </l-tooltip>
                </l-marker>
            </l-map>
        </div>
    </div>
</template>
<script>

import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon, LControl } from "vue2-leaflet";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { perm_mixin } from '@/mixins/permission.mixin'
import ProviderService from '@/services/v3/provider.service'
import StoreService from '@/services/v3/stores/store.service'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'

export default {
    components: {
        LMap,
        LIcon,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
        LControl,
        StandardInput
    },
    mixins: [ perm_mixin, ],
    data(){
        return {
            svc: new ProviderService(),
            store_svc: new StoreService(),
            zoom: 12,
            filters: {
                related: false,
                unrelated: false,
                plus: true
            },
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            currentZoom: 0.5,
            toggle_width: 160,
            showParagraph: false,
            providers: [],
            stores: [],
            loading: true,
            mapOptions: {
                zoomSnap: 0.5
            },
            showMap: true,
            statusStates: []
        }
    },
    watch: {
        'filters.name': function(val) {
            let rx = new RegExp(val, 'gi')
            this.providers.forEach(p => p.visible = false)
            this.providers
                .filter(p => p.name.match(rx) || !val)
                .forEach(p => p.visible = true)
        }
    },
    computed: {
        head_office_state() {
            return this.stores.find(s => s.is_head_office).state.fu
        },
        center() {
            return this.coords(this.stores.find(s => s.is_head_office))
        },
        valid_providers() {
            return this.providers
            .filter(p => p.visible)
            .filter(p => p.head_office)
            .filter(p => p.head_office.geolocation)
            .filter(p => !isNaN(p.head_office.geolocation.latitude))
            .filter(p => !isNaN(p.head_office.geolocation.longitude))
        }
    },
    methods: {
        filter_state(evt) {
            this.filters = { related: 0, plus: 0 }
            this.load({ state: evt.value })
        },
        control_filter(cond, evt) {
            switch(cond) {
                case 'related':
                    this.filters = { related: evt.value, plus: false }
                    this.load({ related: 1 })
                    break;
                case 'plus':
                    this.filters = { related: false, plus: evt.value }
                    this.load({ plus: 1 })
                    break;
            }
        },
        coords(store) {
            let lat = parseFloat(store.geolocation.latitude)
            let long = parseFloat(store.geolocation.longitude)
            if(isNaN(lat) || isNaN(long)) {
                return
            }
            return latLng(lat, long)
        },
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        innerClick() {
            alert("Click!");
        },
        load_stores() {
            this.store_svc.load().then(response => {
                this.stores = response.data
                this.loading = false
            })
        },
        getStates(){
                this.svc.States().then(response => response.data).then(response => {
                 let states = response;
                 let filtroStates = [{key: 'todos', value: null}];
                 for (let index = 0; index < states.length; index++) {
                        filtroStates.push({
                            key:states[index].est_nome,
                            value:states[index].est_sigla
                        })

                 }
                 this.statusStates = filtroStates;
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });

        },
        load(params) {
            this.svc.load_providers(params).then(response => {
                this.providers = response.data.map(p => { return { ...p, visible: true } })
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        formatStatesList (list) {
            let response = []
            list.forEach(element => {
                response.push({
                    value: element,
                    text: element.key
                })
            });
            return response
        },
    },
    mounted() {
        this.getStates()
        this.load({ plus: 1 })
        this.load_stores()
    }
}
</script>

<style lang="scss" scoped>
    @import './explore.page';
    .cover-img img {
        width: 50px;
    }
    .map-controls {
        padding-top: 10px;
        margin: auto;
        padding-right: 10px;
        width: 170px;
    }
    .monitors {
    }
    .map-page-guide{
        font-weight: 400;
        font-size: 1em;
        color: #ABABAB;
    }
    .map-page-title{
        font-weight: 600;
        font-size: 2.25em;
        color: #605F5F;
        margin-top: 1vh;
        margin-bottom: 2vh;
    }
</style>
