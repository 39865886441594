<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="detail-header-container">
                <h1 class="detail-header-title">Detalhes: {{ date.mes }}</h1>
                <span @click="close" class="material-icons detail-closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="detail-body-modal">
                <div class="detail-tab-containear">
                    <div class="detail-tab-size" :class="{'detail-tab-size-active': tab=='first'}"
                        v-on:click="tab='first'">
                        <span class="material-icons-outlined desactive-icon" :class="{'active-icon': tab=='first'}">
                            shopping_cart
                        </span>
                        <div class="detail-tab-text">
                            #1 compra
                        </div>
                    </div>
                    <div class="detail-tab-size" :class="{'detail-tab-size-active': tab=='bought'}"
                        v-on:click="tab='bought'">
                        <span class="material-icons-outlined desactive-icon" :class="{'active-icon': tab=='bought'}">
                            currency_exchange
                        </span>
                        <div class="detail-tab-text">
                            Quantidade comprados
                        </div>
                    </div>
                    <div class="detail-tab-size" :class="{'detail-tab-size-active': tab=='register'}"
                        v-on:click="tab='register'">
                        <span class="material-icons-outlined desactive-icon" :class="{'active-icon': tab=='register'}">
                            open_in_browser
                        </span>
                        <div class="detail-tab-text">
                            Quantidade cadastrados
                        </div>
                    </div>
                    <div class="detail-tab-size" :class="{'detail-tab-size-active': tab=='balance'}"
                        v-on:click="tab='balance'">
                        <span class="material-icons-outlined desactive-icon" :class="{'active-icon': tab=='balance'}">
                            balance
                        </span>
                        <div class="detail-tab-text">
                            Lucro/prejuízo
                        </div>
                    </div>

                </div>
                <RegisterTab v-if="tab=='register'" :categories="categories" :date="date" :svc="svc" :setLoading="value=>isLoading=value"/>
                <OrdersTab v-else-if="tab=='bought'" :categories="categories" :date="date" :svc="svc" :setLoading="value=>isLoading=value"/>
                <BalanceTab v-else-if="tab=='balance'" :categories="categories" :date="date" :svc="svc" :setLoading="value=>isLoading=value"/>

            </div>
        </div>
        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import DashboardService from '@/services/v3/dashboard/DashboardService.js'
import RegisterTab from './RegisterTab.vue';
import OrdersTab from './OrdersTab.vue';
import BalanceTab from './BalanceTab.vue';

export default {
    name: 'LegendInfoModal',
    components: {
        RegisterTab,
        OrdersTab,
        BalanceTab
    },
    props: {
        close: {
            type: Function
        },
        date: {
            type: Object
        },
        categories: {
            type: Array
        }
    },
    data() {
        return {
            tab: 'bought',
            svc: new DashboardService(),
            isLoading: false
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-content-container {
    background-color: white;
    width: 90vw;
    border-radius: 12px;
}


.detail-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 2vw;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.detail-header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.5em;
}

.detail-closebtn-icon {
    color: #ffffff;
    font-size: 3em;
    cursor: pointer;
}

.detail-body-modal {
    padding: 5vh 3vw;
}
.detail-tab-containear{
        border-bottom: 0.5px solid #E5E5E5;
        display: flex;
        align-items: flex-end;
        padding-bottom: 1vh;
    }

    .detail-tab-size{
        background-color: white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 7.5vh;
        min-width: 12vw;
        padding: 0 1vw;
        display: flex;
        align-items: center;
        margin-right: 2vw;
    }
    .detail-tab-size:hover {
        background-color: var(--primary-color-opacity);
    }
    .detail-tab-size-active{
        cursor: default;
        border-radius: 8px 8px 8px 8px;
        background-color: var(--primary-color-opacity);
    }
    .detail-tab-size-active > .detail-tab-text{
        color: #202020 !important;
    }
    .detail-tab-text{
        font-weight: 600;
        font-size: 1.1em;
        color: #202020;
        margin-left: 1vw;
    }
    .detail-tab-size:hover > .detail-tab-text{
        color: var(--primary-color);
    }
    .active-icon{
        color: var(--primary-color) !important;
    }
    .desactive-icon{
        color: var(--primary-color);
    }
</style>