<template>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <form @submit.prevent="() => getSellers()">
                            <div class="row py-3">
                                <div class="col col-sm-12 col-lg" style="margin-left: 13px;">
                                    <label for="" class="label-control">Buscar por nome</label>
                                    <cc-search v-model="name_filter" :noicon="true" />
                                </div>
                                <div class="col col-md-2 col-lg">
                                    <label for="" class="label-control">Selecione um status</label>
                                    <cc-select-v2 :tracker="'value'"
                                        :text="'key'"
                                        :options="statuses"
                                        v-model="status_filter"></cc-select-v2>
                                </div>
                                 <div class="col col-md-2 col-lg">
                                    <label for="" class="label-control">Selecione um estado</label>
                                    <cc-select-v2 :tracker="'value'"
                                        :text="'key'"
                                        :options="statusStates"
                                        v-model="status_filter_states"></cc-select-v2>
                                </div>
                                <div class="col filter-controls">
                                    <cc-button-seach
                                        type="submit"
                                        :nolink="true" />
                                    <cc-button-clean
                                        type="button"
                                        :nolink="true"
                                        :content="'Limpar Filtros'"
                                        @click="name_filter=null;status_filter='';provider_filter='';getSellers()" />
                                </div>
                                <div class="row align-items-end" v-if="can('CLIENT_SELLER', 'UPDATE')">
                                    <cc-button-group
                                        type="button"
                                        :content="'Grupos'"
                                        @click="manage_groups=true" />
                                </div>
                                <div class="lastButton">
                                    <cc-button-invitation
                                        type="button"
                                        :content="'Convites'" @click="show_invitations=true" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-12 p-4 ">
                        <cc-loader v-show="is_loading" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Empresa</th>
                                    <th>CNJP</th>
                                    <th>Vendedor</th>
                                    <th>Contato</th>
                                    <th>Email</th>
                                    <th class="text-center">Reputação</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="seller in sellers" v-bind:key="seller.id">
                                    <td>
                                        <div class="provider-cover rounded">
                                            <img :src="seller.provider.cover_url" v-if="seller.provider.cover_url != 'https://via.placeholder.com/100x50/FFFFFFFF/var(--primary-color)/?text=CLUB'">
                                            <i class="fas fa-user-tie seller-icon" v-else />
                                        </div>
                                    </td>
                                    <td class="text-uppercase">
                                        {{ seller.provider.name }}
                                    </td>
                                    <td>{{ seller.provider.cnpj || "" | VMask('## ### ###/####-##') }}</td>
                                    <td>{{ seller.name }}</td>
                                    <td><cc-whatsapp :phone="seller.phone"/></td>
                                    <td>{{ seller.email }}</td>
                                    <td class="text-center">
                                        <span v-if="parseInt(seller.rate) != 0" class="rate" @click="current_provider = seller"><i class="fa fa-star" aria-hidden="true"></i> ({{ rating(seller) }})</span>
                                        <span v-if="parseInt(seller.rate) == 0" class="rate" @click="current_provider = seller"><i class="fa fa-star" aria-hidden="true"></i> (5.0)</span>
                                    </td>
                                    <td class="text-center">
                                        <toggle-button
                                            :value="seller.provider.blacklists.length == 0"
                                            @change="toggleSellerStatus(seller)"
                                            :disabled="!can('CLIENT_SELLER', 'UPDATE')"
                                            :sync="true" :color="'var(--primary-color)'" :height="30"
                                            :labels="{ checked: 'Ativo', unchecked: 'Bloqueado' }"
                                            :width="seller.provider.blacklists.length == 0 ? 75 : 95"/>
                                    </td>
                                    <td class="text-center actions">
                                        <i class="fa fa-trash"
                                            :class="{ disabled: !can('CLIENT_SELLER', 'UPDATE') }"
                                            @click="can('CLIENT_SELLER', 'UPDATE') && remove_seller(seller)"></i>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer clearfix not-print">
                    <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="paginaAtual"
                        :page_limit="11"
                        v-on:change="getSellers($event)"></cc-paginacao>
                </div>
            </div>
        </div>
        <cc-seller-review :seller="current_provider" v-if="current_provider" @close="current_provider = null" />
        <cc-invitations-modal v-if="show_invitations" @close="show_invitations = false" @load_sellers="getSellers()"/>
        <ManageRequestSellersModal id="modal" v-if="manage_groups" @close="manage_groups = false" />
    </div> -->
    <div class="page" v-on:click="closeOptionsSelect()">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Fornecedores
            </div>
        </div>

        <div class="print-only print-logo-container">
			<img style="width: 22vw;" :src="whitelabel ? whitelabel.data.imgs.img_logo : require('@/assets/Logo-BRANCA.svg')">
		</div>
        <div class="page-title ">Fornecedores</div>
        <div class="page-title-print">Meus Fornecedores</div>

        <div class="page-head-buttons">
            <StandardButton :action="() => { manage_groups=true; showGroupSellersModal = true }" :iconName="'groups'" :text="'Grupo'" />
            <StandardButton :action="() => { show_invitations=true; showInvitationModal = true}" :iconName="'email'" :text="'Convite'" class="page-head-button-email"/>
            <div class="page-head-button-email"  style="position: relative;" v-on:click.stop="() => {}">
                <StandardButton :action="openOptionsSelect" :iconName="'expand_more'" :text="'Exportar'"/>
                <div v-if="showOptionSelect" class="winners-page-options-select">
                    <div v-on:click="printSellers()" class="winners-page-options-select-option">Imprimir PDF</div>
                    <div v-on:click="excel()" class="winners-page-options-select-option">Exportar Excel</div>
                    
                </div>
            </div>
            <!-- <StandardButton :action="printSellers" :iconName="'print'" :text="'Imprimir'" class="page-head-button-email"/>
            <StandardButton :action="excel" :iconName="'description'" :text="'Exportar Excell'" class="page-head-button-email"/> -->
            <StandardButton :action="() => { showAddProvider = true }" :iconName="'add'" :text="'Novo Fornecedor'" class="page-head-button-email"/>
        </div>
        <div class="page-filter-containears">
            <StandardInput class="page-filter-search desktop" :action="setNameFilter" :title="'Buscar por nome'" :width="'35%'" :type="'text'"/>
            <StandardInput class="page-filter-search mobile" :action="setNameFilter" :title="'Buscar por nome'" :width="'100%'" :type="'text'"/>

            <StandardInput class="page-filter-status desktop" :title="'Status'" :action="setStatusFilter" :width="'25%'" :type="'select'" :list="status"/>
            <StandardInput class="page-filter-status mobile" :title="'Status'" :action="setStatusFilter" :width="'100%'" :type="'select'" :list="status"/>

            <StandardInput class="desktop" :title="'Estado'" :width="'25%'" :type="'select'" :action="setStateFilter" :list="formatStatesList(statusStates)"/>
            <StandardInput class="mobile" :title="'Estado'" :width="'100%'" :type="'select'" :action="setStateFilter" :list="formatStatesList(statusStates)"/>

        </div>
        <div class="selected-value-containear">
            <div v-if="name_filter != ''" v-on:click="setNameFilter('')" class="selected-value">
                <div>{{name_filter}}</div>
                <span class="material-icons selected-value-icon-close">close</span>
            </div>
            <div v-if="status_filter.value" v-on:click="setStatusFilter(null)" class="selected-value">
                <div>{{formatStatus(status_filter.value)}}</div>
                <span class="material-icons selected-value-icon-close">close</span>
            </div>
            <div v-if="status_filter_states.value" v-on:click="setStateFilter({value: null})" class="selected-value">
                <div>{{status_filter_states.value}}</div>
                <span class="material-icons selected-value-icon-close">close</span>
            </div>
        </div>

        <!-- <div class="page-table-containear">
            <div class="page-table">
                <div class="page-table-header">
                    <div class="page-table-store-width page-table-store-width-header">Empresa <br> CNPJ</div>
                    <div class="page-table-cnpj-width">Vendedor <br/> Contato</div>
                    <div class="page-table-state-width">Email</div>
                    <div class="page-table-city-width">Participação</div>
                    <div class="page-table-phone-width">Avaliação</div>
                    <div class="page-table-status-width">Status</div>
                    <div class="page-table-actions-width">Ações</div>
                </div>
                <div class="page-table-line"
                     v-for="(seller, index) in sellers"
                     :key="seller.id"
                     :class="getSpecialBackground(index)">
                    <div class="page-table-store-width page-table-separator page-table-store">
                        <img class="provider-cover rounded" :src="seller.provider.cover_url" v-if="seller.provider.cover_url != 'https://via.placeholder.com/100x50/FFFFFFFF/var(--primary-color)/?text=CLUB'">
                        <span v-else class="material-icons page-table-user-icon">person</span>
                        <div class="seller-table-mobile-align">{{seller.provider.name}} <br> {{seller.provider.cnpj | VMask('##.###.###/####-##')}}</div>
                    </div>

                    <div class="page-table-cnpj-width page-table-separator">
                        <div class="page-mobile-header">Vendedor <br> Contato</div>
                        <div class="seller-table-mobile-align">
                            {{seller.name}}
                            <div style="display: flex; align-items: center;">
                                <i class="fab fa-whatsapp page-table-contact-icon"></i>
                                <a v-if="seller.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(seller.phone)" target="_blank" class="page-table-contact-text">{{seller.phone | VMask('(##) ####-#####')}}</a>
                                <div v-else class="page-table-contact-text">(xx) xxxxx-xxxxx</div>
                            </div>
                        </div>

                    </div>
                    <div class="page-table-state-width page-table-separator">
                        <div class="page-mobile-header">Email</div>
                        {{seller.email}}
                    </div>
                    <div class="page-table-city-width">
                        <div class="page-mobile-header">Participação</div>
                        <div class="seller-participation-icon" v-if="seller.participation" :class="getPercent(seller.participation)">{{(seller.participation * 100).toFixed(2) + ' %'}}</div>
                    </div>
                    <div class="page-table-phone-width page-table-separator">
                        <div class="page-mobile-header">Avaliação</div>
                        {{ rating(seller) }}
                    </div>
                    <div class="page-table-status-width page-table-separator page-table-status">
                        <div class="page-mobile-header">Status</div>
                        <div class="text-center" v-if="can('CLIENT_SELLER', 'UPDATE')" v-on:click="toggleSellerStatus(seller)">
                            <span v-if="seller.provider.blacklists.length == 0" style="cursor: pointer; color: var(--primary-color);" class="material-icons-outlined">verified</span>
                            <span v-else v-on:click="updateProductStatus(data)" style="cursor: pointer;" class="material-icons-outlined">radio_button_unchecked</span>
                        </div>
                        <div v-else>--</div>
                    </div>
                    <div class="page-table-actions-width">
                        <div class="page-mobile-header">Ações</div>
                        <span title="Deletar fornecedor" class="material-icons page-table-delete-icon" v-on:click="remove_seller(seller)">delete</span>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="page-table-containear">
            <div class="page-table">
                <div class="page-table-header not-print">
                    <div class="page-table-expand-width"></div>
                    <div class="page-table-store-width">Fornecedor</div>
                    <div class="page-table-cnpj-width">CNPJ</div>
                    <div class="page-table-state-width">Estado</div>
                    <div class="page-table-city-width">Cidade</div>
                    <div class="page-table-phone-width">Telefone</div>
                    <div class="page-table-block-width"></div>
                </div>
                <div class="page-table-header print-only">
                    <div class="page-table-expand-width"></div>
                    <div class="page-table-store-width">Empresa / CNPJ</div>
                    <div class="page-table-cnpj-width">Vendedor / Telefone</div>
                    <div class="page-table-state-width">Estado</div>
                    <div class="page-table-city-width">Cidade</div>
                    <div class="page-table-phone-width">Status</div>
                    <div class="page-table-block-width"></div>
                </div>
                <div v-for="(seller, index) in sellers" :key="seller.id">
                    <div class="page-table-line not-print"  style="border-bottom: #E5E5E5 1px solid">

                        <div class="page-table-expand-width">
                            <span v-on:click="toggleIsExpanded(seller)" v-if="!seller.isExpanded" class="material-icons-outlined page-table-expand-icon">expand_more</span>
                            <span v-on:click="toggleIsExpanded(seller)" v-else class="material-icons-outlined page-table-expand-icon">expand_less</span>
                            <img class="provider-cover rounded" :src="seller.cover_url" v-if="seller.cover_url != 'https://via.placeholder.com/100x50/FFFFFFFF/ff7110/?text=CLUB'">
                            <span v-else class="material-icons page-table-user-icon">local_shipping</span>

                            <!-- mobile -->
                            <div class="mobile">
                                <div class="page-mobile-header">CNPJ</div>
                                {{seller.cnpj | VMask('##.###.###/####-##')}}
                            </div>
                            <!-- mobile -->
                            <div class="mobile" style="margin-left: auto;">
                                <div class="page-mobile-header">Telefone</div>
                                <a v-if="seller.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(seller.phone)" target="_blank" class="page-table-contact-text">{{seller.phone | VMask('(##) ####-#####')}}</a>
                                <div v-else class="page-table-contact-text">(xx) xxxxx-xxxxx</div>
                            </div>
                        </div>

                        <div class="page-table-store-width page-table-separator page-table-store">
                            <div class="seller-table-mobile-align">{{seller.name}}</div>
                        </div>

                        <!-- desktop -->
                        <div class="page-table-cnpj-width page-table-separator desktop">
                            <div class="page-mobile-header">CNPJ</div>
                            {{seller.cnpj | VMask('##.###.###/####-##')}}
                        </div>
                        <!-- desktop -->
                        <div class="page-table-state-width page-table-separator  desktop">
                            <div class="page-mobile-header">Estado</div>
                            {{seller.head_office ? seller.head_office.state.name : '--'}}
                        </div>
                        <!-- desktop -->
                        <div class="page-table-city-width  desktop">
                            <div class="page-mobile-header">Cidade</div>
                            {{seller.head_office ? seller.head_office.city.name: '--'}}
                        </div>
                        <!-- desktop -->
                        <div class="page-table-phone-width page-table-separator  desktop">
                            <div class="page-mobile-header">Telefone</div>
                            <a v-if="seller.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(seller.phone)" target="_blank" class="page-table-contact-text">{{seller.phone | VMask('(##) ####-#####')}}</a>
                            <div v-else class="page-table-contact-text">(xx) xxxxx-xxxxx</div>
                        </div>
                        <!-- desktop -->
                        <div class="page-table-block-width desktop" v-if="seller.blacklists">
                            <toggle-button
                                            :value="seller.blacklists.length == 0"
                                            @change="toggleSellerStatus(seller)"
                                            :disabled="!can('CLIENT_SELLER', 'UPDATE')"
                                            :sync="true" :color="'var(--primary-color)'" :height="30"
                                            :labels="{ checked: 'Ativo', unchecked: 'Bloqueado' }"
                                            :width="seller.blacklists.length == 0 ? 75 : 95"/>
                        </div>



                        <!-- mobile -->
                        <div class="mobile mobile-row-table">
                            <div >
                                <div class="page-mobile-header">Estado</div>
                                {{seller.head_office ? seller.head_office.state.name : '--'}}
                            </div>
                            <div style="display: flex; flex-direction: column; align-items: flex-end;">
                                <div class="page-mobile-header">Cidade</div>
                                {{seller.head_office ? seller.head_office.city.name : '--'}}
                            </div>
                            <toggle-button v-if="seller.blacklists"
                                :value="seller.blacklists.length == 0"
                                @change="toggleSellerStatus(seller)"
                                :disabled="!can('CLIENT_SELLER', 'UPDATE')"
                                :sync="true" :color="'var(--primary-color)'" :height="30"
                                :labels="{ checked: 'Ativo', unchecked: 'Bloqueado' }"
                                :width="seller.blacklists.length == 0 ? 75 : 95"/>
                        </div>

                    </div>
                    <div v-if="seller.is_loading" class="page-table-expand-loading">
                        <span class="material-icons-outlined page-table-loading-icon">refresh</span>
                    </div>
                    <div v-if="seller.isExpanded" >
                        <div class="page-table-header page-table-subheader expanded-line">
                            <div class="page-table-expand-width">Id</div>
                            <div class="page-table-store-width">Nome do vendedor</div>
                            <div class="page-table-cnpj-width">Telefone</div>
                            <div class="page-table-email-width">Email</div>
                            <div class="page-table-rate-width">Tipo</div>
                            <div class="page-table-rate-width">Nota</div>
                            <div class="page-table-action-width">Ação</div>
                        </div>
                        <div v-for="test in seller.sellers" :key="test.name" class="page-table-line expanded-line"  >
                            <div class="page-table-expand-width">
                                {{ test.id }}
                            </div>

                            <div class="page-table-store-width page-table-separator page-table-store">
                                <div class="seller-table-mobile-align">{{test.name}}</div>
                                <div class="mobile ">
                                    <span title="Deletar fornecedor" class="material-icons page-table-delete-icon" v-on:click="remove_seller(test)">delete</span>
                                </div>
                            </div>

                            <!-- desktop -->
                            <div class="desktop page-table-cnpj-width page-table-separator">
                                <div class="page-mobile-header">Telefone</div>
                                <a v-if="test.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(test.phone)" target="_blank" class="page-table-contact-text">{{test.phone | VMask('(##) ####-#####')}}</a>
                                <div v-else class="page-table-contact-text">(xx) xxxxx-xxxxx</div>
                            </div>
                            <!-- desktop -->
                            <div class="desktop page-table-email-width page-table-separator">
                                <div class="page-mobile-header">Email</div>
                                {{test.email}}
                            </div>
                            <!-- desktop -->
                            <div class="desktop page-table-rate-width">
                                {{ test.type == 'REQUEST' ? 'Cotação' : 'Vendedor exclusivo' }}
                            </div>
                             <!-- desktop -->
                             <div class="desktop page-table-rate-width">
                                {{rating(test)}}
                            </div>
                            <!-- desktop -->
                            <div class="desktop page-table-action-width page-table-separator">
                                <span title="Deletar fornecedor" class="material-icons page-table-delete-icon" v-on:click="remove_seller(test, seller)">delete</span>
                                <span title="Atualizar tipo fornecedor" class="material-icons page-table-update-icon" @click="syncSuplier(test, seller)">swap_horiz</span>
                            </div>

                            <!-- mobile -->
                            <div class="mobile mobile-row-table">
                                <div >
                                    <div class="page-mobile-header">Telefone</div>
                                    <a v-if="test.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(test.phone)" target="_blank" class="page-table-contact-text">{{test.phone | VMask('(##) ####-#####')}}</a>
                                    <div v-else class="page-table-contact-text">(xx) xxxxx-xxxxx</div>
                                </div>
                                <div >
                                    <div class="page-mobile-header">Email</div>
                                    {{test.email}}
                                </div>
                                <div >
                                    <div class="page-mobile-header">Nota</div>
                                    {{rating(test)}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="print-only page-table-line" v-if="seller.provider ">
                        <div class="page-table-expand-width"></div>
                        <div class="page-table-store-width">
                            {{ seller.provider.name }} <br>
                            {{ seller.provider.cnpj }}
                        </div>
                        <div class="page-table-cnpj-width">
                            {{ seller.name }} <br>
                            {{ seller.phone ? seller.phone : '--' }}
                        </div>
                        <div class="page-table-state-width">
                            {{ seller.provider.head_office.state.name }}
                        </div>
                        <div class="page-table-city-width">
                            {{ seller.provider.head_office.city.name }}
                        </div>
                        <div class="page-table-phone-width">
                            {{ seller.provider.blacklists.length == 0 ? 'Ativado' : 'Bloqueado' }}
                        </div>
                        <div class="page-table-block-width"></div>
                    </div>
                </div>
            </div>
        </div>


        <cc-loader-full v-if="is_loading"/>
        <newProviderModal v-if="showNewProvider" :reload="getSellers" @close="() => showNewProvider = false"/>
        <AddProvider v-if="showAddProvider" :close="() => showAddProvider = false" />
        
        <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="paginaAtual"
                        :page_limit="11"
                        v-on:change="getSellers($event)"></cc-paginacao>

        <cc-seller-review :seller="current_provider" v-if="current_provider" @close="current_provider = null" />
        <cc-invitations-modal v-if="show_invitations && showInvitationModal"
                              :closeDialog="closeInvitationModal"
                              @close="show_invitations = false"
                              @load_sellers="getSellers()"/>
        <ManageRequestSellersModal id="modal"
                                   v-if="manage_groups && showGroupSellersModal"
                                   :closeDialog="closeGroupSellersModal"
                                   @close="manage_groups = false" />
    </div>
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import QuotationService from "@/services/QuotationService";
import ProviderServiceV3 from "@/services/v3/provider.service";
import ProviderService from "@/services/ProviderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import SellerReviewModal from '@/modals/seller-review/seller-review.modal';
import ManageRequestSellersModal from "@/modals/manage-seller-groups/manage-seller-groups.modal";
import InvitationsModal from "@/modals/client/invitations/invitations.modal";
import { perm_mixin } from '@/mixins/permission.mixin'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import AddProvider from "./addProvider/AddProvider.vue";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import newProviderModal from './new/new-provider.modal.vue';
import { mapState } from "vuex";
import ExcelDownload from '@/services/ExcelDownload.js'

export default {
    name: 'SellersPage',
    mixins: [ perm_mixin, loaderMixin ],
    components: {
        ManageRequestSellersModal,
        ccPaginacao: Paginacao,
        'cc-seller-review' : SellerReviewModal,
        'cc-invitations-modal' : InvitationsModal,
        StandardButton,
        StandardInput,
        newProviderModal,
        AddProvider
    },
    data(){
        return{
          image:"/images/search.png",
            is_loading: false,
            paginaAtual: 1,
            total_items: 1,
            name_filter: "",
            show_invitations: false,
            showInvitationModal: true,
            showGroupSellersModal: true,
            status_filter: { key: "Todos", value: null },
            status_filter_states: { key: "Todos", value: null },
            showOptionSelect: false,
            provider_filter: null,
            sellersName: '',
            showAddProvider: false,
            sellersEstado:'',
            sellersCidade:'',
            sellersFornecedor: '',
            isMinhasAvaliacoes: true,
            isOutrasAvaliacoes: false,
            isClassButton: true,
            sellersTodasAvaliacoes:[],
            sellersMinhasAvaliacoes:[],
            notatotal: 0,
            items_by_page_value: 50,
            service: new QuotationService(),
            prov_svc: new ProviderService(),
            svc: new ProviderServiceV3(),
            showNewProvider: false,
            providers: [],
            sellers: [],
            status: [ { text: 'Todos', value: null }, { text: 'Bloqueados', value: "BLOCKED" }, { text: 'Ativos', value: "ACTIVE" } ],
            statusStates: [],
            current_provider: null,
            manage_groups: false,
            breadcrumbs: [
                {
                    name: "Meus Vendedores",
                    route: 'sellers'
                }
            ]
        }
    },
    computed: {
        ...mapState([ "whitelabel"]),
    },
    methods: {
        getPercent(value) {
            if (value <= 0.3) return 'table-not-typed'
            else if (value >= 0.7) return 'table-typed'
            else return 'percent-orange'
        },
        syncSuplier (test, seller) {
            seller.update = true
                seller.isExpanded =  false
                seller.is_loading = true
                this.$forceUpdate()
                const type = test.type == 'REQUEST' ? 'DIRECT_REQUEST' : 'REQUEST'
                this.prov_svc.syncProvider(test.id,type).then(() => {
                    this.toggleIsExpanded(seller)
                })
        },
        async toggleIsExpanded (seller) {
            if (seller.blacklists.length > 0) return

            if(!seller.sellers[0]?.review || seller.update){
                seller.is_loading = true
                this.$forceUpdate()
                for (let index = 0; index < seller.sellers.length; index++) {
                    const element = seller.sellers[index];
                    const resp = await this.svc.getReview(element.id)
                    element.review = resp.data.average_rating
                    element.type = resp.data.type
                }
            }
            seller.is_loading = false
            seller.isExpanded =  !seller.isExpanded
            this.$forceUpdate()
        },
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        openOptionsSelect () { this.showOptionSelect = true },
        closeOptionsSelect () { this.showOptionSelect = false },
        closeInvitationModal: function() {
            this.showInvitationModal = false;
        },
        closeGroupSellersModal: function() {
            this.showGroupSellersModal = false;
        },
        formatStatus (value) {
            if (value == 'BLOCKED') return 'Inativos'
            if (value == 'ACTIVE') return 'Ativos'
        },
        setStatusFilter(value){
            this.status_filter = { key: "Todos", value: value }
            this.reloadSellers()
        },
        setStateFilter(value){
            this.status_filter_states = { key: "Todos", value: value.value }
            this.reloadSellers()
        },
        setNameFilter (value) {
            this.name_filter = value
            this.reloadSellers()
        },
        formatStatesList (list) {
            let response = []
            list.forEach(element => {
                response.push({
                    value: element,
                    text: element.key
                })
            });
            return response
        },
        remove_seller(seller, seller2) {
            this.confirm_action({
                message: `Confirma remoção do vendedor ${seller.name}?` ,
                callback: () => this.remove(seller, seller2)
            })
        },
        remove(seller, seller2) {
            return this.prov_svc.remove_seller(seller.id).then(() => {
                seller2.sellers = seller2.sellers.filter(value => value.id != seller.id)
            })
        },
        toggleSellerStatus(seller) {
            if(seller.blacklists.length > 0) {
                this.remove_from_blacklist(seller);
            } else {
                this.blacklist(seller)
            }
        },
        rating(seller) {
            // let result = (seller.ratings.reduce((x, y) => x += y.score, 0)/seller.ratings.length).toFixed(1)
            // return isNaN(result) ? '5.0' : result
            return seller.review ? seller.review+'.0' : '--'
        },
        blacklist(provider) {
            this.$swal.fire({
                title: "Bloquear fornecedor?",
                text: `O fornecedor ${provider.name} será bloqueado!`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, confirmar!",
                animation: "slide-from-top",
                input: 'text',
                inputPlaceholder: 'Justificativa'
            }).then(result => {
                if (result.isConfirmed) {
                    this.is_loading = true;
                    this.svc.blacklist(provider.id, result.value).then(() => {
                        this.is_loading = false;
                        this.$set(provider, 'has_tags', !provider.has_tags);
                        this.$swal.fire(
                            "Ativado!",
                            "Vendedor bloqueado com sucesso.",
                            "success"
                        );
                        this.getSellers(this.paginaAtual);
                    }, err => {
                        this.is_loading = false;
                        this.$swal.fire(
                            "Erro!",
                            "Ocorreu um erro ao bloquear o vendedor.",
                            "error"
                        );
                    });
                }
            });
        },
        remove_from_blacklist(provider) {
            this.$swal.fire({
                title: "Desbloquear vendedor?",
                text: `O fornecedor ${provider.name} será desbloqueado!`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, confirmar!",
                animation: "slide-from-top",
            }).then(result => {
                if (result.isConfirmed) {
                    this.is_loading = true;
                    this.svc.remove_from_blacklist(provider.id).then(() => {
                        this.is_loading = false;
                        this.$set(provider, 'has_tags', !provider.blocked);
                        this.$swal.fire(
                            "Desativado!",
                            "Vendedor desbloqueado com sucesso.",
                            "success"
                        );
                        this.getSellers(this.paginaAtual);
                    }, err => {
                        this.is_loading = false;
                        this.$swal.fire(
                            "Erro!",
                            "Ocorreu um erro ao desbloquear o vendedor.",
                            "error"
                        );
                    });
                }
            });
        },
        reloadSellers(selected) {
            if(selected) {
            this.provider_filter = selected;
            }
            this.getSellers(this.paginaAtual);
        },
        excel(){
            this.is_loading = true;
            let params = {
                page: 1,
                page_size: 1000,
                name: this.name_filter,
                status: this.status_filter ? this.status_filter.value : null,
                state: this.status_filter_states ? this.status_filter_states.value : null
            }
            this.svc.load(params).then(response => response.data).then(response => {
                
                this.is_loading = false;
                const oldSellers = this.sellers
                this.sellers = response.data
                setTimeout(() => {
                    const payload = {
                        tableName: 'Fornecedores',
                        userName: localStorage.getItem('user_name'),
                        store: localStorage.getItem('grupo'),
                        author: this.whitelabel && this.whitelabel.data.providers.text_window,
                        headerColor: this.whitelabel && this.whitelabel.data.colors.primary,
                        header: ['id', 'Fornecedor', 'CNPJ', 'Estado', 'Cidade', 'Telefone', 'Vendedor', 'Tel. vendedor', 'Email', 'Status'],
                        body: this.sellers.map(value => {
                            return [
                                String(value.id),
                                value.provider.name,
                                value.provider.cnpj,
                                value.provider.head_office ? value.provider.head_office.state.name : '--',
                                value.provider.head_office ? value.provider.head_office.city.name: '--',
                                value.phone || '--',
                                value.name,
                                value.phone || '--',
                                value.email,
                                (value.provider.blacklists.length == 0) ? 'ativo' : 'bloqueado'
                            ]
                        })
                    }
                    new ExcelDownload(payload)
                    this.sellers = oldSellers
                    this.closeOptionsSelect()
                }, 100);
            })
            
        },
        printSellers () {
            this.is_loading = true;
            let params = {
                page: 1,
                page_size: 1000,
                name: this.name_filter,
                status: this.status_filter ? this.status_filter.value : null,
                state: this.status_filter_states ? this.status_filter_states.value : null
            }
            this.svc.load(params).then(response => response.data).then(response => {
                this.is_loading = false;
                const oldSellers = this.sellers
                this.sellers = response.data
                setTimeout(() => {
                    window.print()
                    this.sellers = oldSellers
                    this.closeOptionsSelect()
                }, 100);
            })
        },
        getSellers(new_page = this.paginaAtual) {
            this.paginaAtual = new_page;
            this.is_loading = true;
            let params = {
                page: this.paginaAtual,
                page_size: this.items_by_page_value,
                name: this.name_filter,
                status: this.status_filter ? this.status_filter.value : null,
                state: this.status_filter_states ? this.status_filter_states.value : null
            }
            this.svc.load(params).then(response => response.data).then(response => {
                const stores = []
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    let add = true
                    stores.forEach(element2 => {
                        if (element.provider.name == element2.name){
                            add = false
                            element2.sellers.push(element)
                        }
                    });
                    if (add) {
                        stores.push({...element.provider, sellers: [element]})
                    }
                }
                this.sellers = stores;
                this.total_items = response.meta.total;
                this.is_loading = false;
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getStates(){
                this.svc.States().then(response => response.data).then(response => {
                 let states = response;
                 let filtroStates = [{key: 'todos', value: null}];
                 for (let index = 0; index < states.length; index++) {
                        filtroStates.push({
                            key:states[index].est_nome,
                            value:states[index].est_sigla
                        })

                 }
                 this.statusStates = filtroStates;
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });

        },
        closeModal(){
            this.$modal.hide('sellers')
            this.myAvaliacoes()
        },
        myAvaliacoes(){
            this.isMinhasAvaliacoes = true
            this.isOutrasAvaliacoes = false
            this.isClassButton = true
        },
        outrasAvaliacoes(){
            this.isMinhasAvaliacoes = false
            this.isOutrasAvaliacoes = true
            this.isClassButton = false
        },
    },
    created() {
      this.getStates();
      this.getSellers();
      this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';


    .provider-cover {
        width: 75px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            object-fit: fill;
            width: 100%;
            height: 100%;
        }
    }
.page-route-select{
    display: flex;
    align-items: center;
}
.page-route-select-text{
    font-weight: 400;
    font-size: 1.36em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.page-route-select-icon{
    color: var(--primary-color);
    margin-right: 20px;
}
.page{
    padding: 0 40px 70px 0;
    font-size: 1vw;
}
.page-head-buttons{
    display: flex;
    flex-direction: row-reverse;
}
.page-invite-button{
    display: flex;
    border: 0.5px solid #E5E5E5;
    border-radius: 8px;
    padding: 15px 12px;
}
.page-head-button-email{
    margin-right: 15px;
}
.page-filter-containears{
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
}
.page-table-header{
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 600;
    font-size: 1.19em;
    color: #605F5F;
    display: flex;
    padding: 1vh 0;
    margin-top: 5vh;
}
.page-table-store-width{
    width: 25%;
}
.winners-page-options-select{
    position: absolute;
    z-index: 2;
    margin-top: 1vh;
    right: 0;
    padding: 0.5vw;
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    background-color: white;
}
.winners-page-options-select-option{
    font-size: 1.25em;
    padding: 0.5vh;
    min-width: 15vw;
    text-align: end;
    cursor: pointer;
}
.winners-page-options-select-option:hover{
    background-color: var(--primary-color);
    border-radius: 5px;
    color: white;
}

.page-table-expand-icon{
    font-size: 3em;
    color: var(--primary-color);
    cursor: pointer;
}

.page-table-subheader{
    border: 0;
    border-bottom: #E5E5E5 1px solid;
    margin-top: 1vh;
    background-color: transparent;
}

.page-table-document-width{
    width: 12%;
    padding-left: 10px;
}
.page-table-contact-width{
    width: 13%;
    padding-left: 10px;
}
.page-table-cnpj-width{
    width: 20%;
    padding-left: 10px;
}
.page-table-state-width{
    width: 20%;
    padding-left: 10px;
}
.page-table-city-width{
    width: 15%;
    padding-left: 10px;
}
.page-table-phone-width{
    width: 12%;
}
.page-table-rate-width{
    width: 9%;
}
.expanded-line{
    background: rgba(255, 113, 16, 0.10);
}
.page-table-email-width{
    flex: 1;
}
.page-table-action-width{
    width: 7%;
}
.seller-participation-icon{
    color: white;
    border-radius: 10px;
    padding: 0.5vh 0;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    width: 70%;
}
.page-table-expand-loading{
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content: center;

}
.page-table-loading-icon{
    animation-name: spin;
    animation-duration: 2.5s;
    color: var(--primary-color);
    font-size: 4em;
}
@keyframes spin {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 }
.table-not-typed {
    background-color: lightcoral;
  }

  .percent-orange {
    background-color: lightsalmon;
  }

  .table-typed {
    background-color: lightgreen;
  }
.page-table-status-width{
    width: 7%;
    text-align: center;
}
.page-table-status{
    display: flex;
    justify-content: center;
}
.page-table-actions-width{
    flex: 1;
    text-align: center;
}
.page-table-line{
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    margin-top: 1.5vh;
    align-items: center;
    padding: 1vh 0;
}
.page-table-store{
    display: flex;
    align-items: center;
}
.page-mobile-header{display: none;}
.page-table-store-img{
    max-height: 40px;
    max-width: 40px;
}
.page-table-user-icon{
    font-size: 2em;
    color: rgb(164, 163, 163);
    width: 75px;
    text-align: center;
}
.page-table-contact{
    display: flex;
    align-items: center;
}
.page-table-contact-icon{
    color: white;
    background-color: green;
    border-radius: 50%;
    font-size: 1.7em;
    padding: 3px;
    margin-right: 10px;
}
.page-table-contact-text{
    color: green;
}
.page-table-block-width{
    width: 7%;
}
.page-table-delete-icon{
    cursor: pointer;
    color: #CD2A2A;
}
.page-table-update-icon{
    cursor: pointer;
    color: blue;
}

.page-table-expand-width{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.mobile{
    display: none;
}
.selected-value{
    display: flex;
    background: #FCF9F7;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--primary-color);
    padding: 10px;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}
.selected-value-containear{
    display: flex;
    margin-top: 10px;
}
.selected-value-icon-close{
    font-size: 1.7em;
    margin-left: 15px;
}
.print-only {
	display: none;
}
.page-title{
    margin-top: 45px;
    font-weight: 700;
    font-size: 2.72em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #605F5F;
}
.page-title-print{
    display: none;
}
.page-table-line-special{
  background: #F7F7F7;
}
@media only screen and (max-width: 1000px) {
    .page{ font-size: 1.2vw; }
}
@media only screen and (max-width: 800px) {
    .page{ font-size: 2vw; }
    .page-table-header{display: none;}
    .mobile{
        display: block;
    }
    .desktop{
        display: none !important;
    }
    .page-filter-containears{
        flex-direction: column;
    }
    .page-filter-search{
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .page-filter-status{
        margin-bottom: 20px;
    }
    .page{
        padding: 0 40px 70px 0;
    }
    .page-head-buttons{
        margin-top: 20px;
    }
    .page-table-line{
        flex-direction: column;
        gap: 1vh;
        font-size: 1.2em;
    }
    .page-table-separator{
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex: unset;
    }
    .page-mobile-header{
        display: flex;
        font-size: 1em;
        font-weight: 700;
    }
    .page-table-actions-width{
        flex: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
    }
    .page-table-city-width{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .seller-participation-icon{
        width: 40%;
        font-size: 1em;
        padding: 0;
    }
    .seller-table-mobile-align{
        text-align: end;
        font-weight: bold;
        font-size: 1.1em;
    }
    .page-table-phone-width{padding-left: 10px;}
    .page-table-status-width{padding-left: 10px;}
    .page-table-separator{
        border-right: none;
        padding-right: 2vw;
    }
    .mobile-row-table{
        display: flex !important;
        justify-content: space-between;
        width: 100%;
    }
    .page-table-expand-width{
        width: 100%;
        justify-content: flex-start;
    }
}
@media only screen and (max-width: 500px) {
    .page{
        font-size: 3vw;
        padding: 0 2vw 70px 0;
    }
}

@media only print {
    .page-route-select{
        display: none;
    }
    .page-title{
        display: none;
    }
    .page-table-expand-icon{display: none;}
    .page-title-print{
        display: block;
        text-align: center;
        margin: 1vh 0;
        margin-top: 45px;
        font-weight: 700;
        font-size: 2.72em;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #605F5F;
    }
    .page-head-buttons{
        display: none;
    }
    .page-filter-containears{
        display: none;
    }
    .pagination{
        display: none;
    }
    .page-table-actions-width{
        display: none;
    }
    .page-table-state-width{
        width: unset;
        flex: 1;
    }
    .page-table-header{
        margin-top: 0;
    }
    .print-logo-container {
		display: flex !important;
		justify-content: center;
        margin-top: -20vh;
	}

	.print-only {
		display: block;
	}
    .page-table-line{
        display: flex;
        font-size: 1.1em;
        padding: 0;
    }
    .page-table-header{display: flex;}
}

</style>

